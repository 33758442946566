<template>
     <v-row>
          <v-col cols="12">
               <h3>Add uid manager</h3>
          </v-col>
          <v-col cols="12">
               <v-text-field
                    v-model="user_uid"
                    :label="$t('attributes.uid.label')"
                    :placeholder="$t('attributes.uid.placeholder')"
                    :hint="$t('attributes.uid.hint')"
                    :error-messages="errors.user_uid"
               ></v-text-field>
          </v-col>
          <v-col cols="12">
               <v-btn @click="attachManager" color="primary">ADD MANAGER</v-btn>
          </v-col>
          <v-col cols="12" v-if="Object.keys(errors).length">
               <v-alert
                    border="top"
                    colored-border
                    type="error"
                    elevation="2"
               >
                    <ul>
                    <li v-for="(err,field) in errors" :key="field">
                         <b>{{$t('attributes.'+field+'.label')}}</b>: <span v-for="(e,i) in err" :key="i" >{{e}}</span>
                    </li>  
                    </ul>
               </v-alert>
          </v-col>
     </v-row>
</template>

<script>
export default {
     name: "AttachManager",
     props:{
          store_uid:{
               type: String,
               required: true
          }
     },
     data: () => ({
          loading: null,
          user_uid: null,
          errors:{},
          message: null
     }),
     methods:{
          async attachManager(){
               this.loading = true;
               this.errors = {};
               var params = {
                    store_uid: this.store_uid,
                    user_uid: this.user_uid
               }
               this.$store.dispatch('adminStores/attachManagerStore',params)
                    .then((response) => {
                         console.log('attachManagerStore success!',response)
                         this.message = response.data.message
                         this.loading = false;
                         this.errors = {};
                         location.reload();
                    })
                    .catch((err) => {
                         console.error('attachManagerStore ERROR!',err)
                         this.message = err.response.data.message
                         this.errors = err.response.data.errors
                         this.loading = false;
                    }) 
          }
     }
}
</script>