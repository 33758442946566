<template>
     <v-row>
          <v-col cols="11" class="mx-auto my-4" v-if="isAdmin">
               <v-card :loading="loading">
                    <v-card-text>
                         <paginated-table 
                           :headers="headers" 
                           :table="receipts" 
                           id="receipt_uid" 
                           :meta="meta"
                           :loading="loading"
                           view
                           name="admin.receipts.single"  
                           @change="getUserReceipts"
                         ></paginated-table>
                    </v-card-text>
               </v-card>
          </v-col>
     </v-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
     name:"ReceiptsList",
     props:{
          uid:{
               type: String,
               required: true,
          }
     },
     computed: {
          ...mapGetters({
                receipts: 'adminUsersReceipts/receipts',
                meta: 'adminUsersReceipts/meta',
                isAdmin: 'auth/isAdmin',
          }),
     },
     beforeMount(){
          
          this.getUserReceipts()
          
     },
     data: () => ({
          loading: true,
          headers:[
               // {text:'#', value: 'receipt_uid', sortable: false},
               // {text:'user', value: 'user.fullname', sortable: false},
               {text:'attributes.store_name.label', value: 'store.name', sortable: false},
               {text:'attributes.amount.label', value: 'amount', sortable: false},
               {text:'attributes.issued_on.label', value: 'issued_on', sortable: false},
          ],
          errors: null,
          message: null,
     }),
     methods:{
          async getUserReceipts(){
               this.$store.dispatch('adminUsersReceipts/getUserReceipts',this.uid).then((response) => {
                    console.log('getUserReceipts success!',response)
                    this.loading = false;
               })
               .catch((err) => {
                    console.error('LOGIN ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               }) 
          },
          page(page){
               this.loading = true
               this.meta.current_page = page
               this.$store.commit('adminUsersReceipts/meta',this.meta)
               this.getUserReceipts()
          }
     },
}
</script>