<template>
     <div>
          <v-container class="my-5" >
          <v-row>
               <v-col cols="12">
                   <v-card :loading="loading">
                        <v-card-title primary-title v-if="coupon">
                             <div>
                                  <h3>{{coupon.title}}</h3>
                             </div>
                             <v-spacer></v-spacer>
                              {{coupon.coupon_code}}
                        </v-card-title>
                        <v-card-text v-if="coupon">
                             {{coupon.description}}
                        </v-card-text>
                   </v-card>
               </v-col>
               <v-col cols="12" md="8">
                   <v-card :loading="loading">
                        <v-card-title primary-title>
                            {{ $t('commons.coupon') }}
                            <v-spacer></v-spacer>
                            <v-btn color="primary" icon @click="edit = !edit">
                                 <v-icon v-if="!edit">mdi-pencil</v-icon>
                                 <v-icon v-else>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text v-if="coupon && !edit">
                             <simple-table :table="{
                                  coupon_code: coupon.coupon_code,
                                  type: $t('commons.coupons_types.'+coupon.type),
                                  discount_amount: amount(coupon.discount_amount,coupon.type),
                                  points: '-'+coupon.points,
                                  start: coupon.start,
                                  end: coupon.end,
                                  active: (coupon.active) ? true : false,
                                  autoadd: (coupon.autoadd) ? true : false,
                                  replicable: (coupon.replicable) ? true : false,
                                  valid_levels: coupon.valid_levels,
                                  expire_in_days: coupon.expire_in_days,
                                  updated_at: coupon.updated_at,
                             }" ></simple-table> 
                        </v-card-text>
                        <v-card-text v-if="edit">
                             <update-coupon :coupon="coupon"></update-coupon>
                        </v-card-text>
                   </v-card>
               </v-col>
          </v-row>
          </v-container>
     </div>
</template>


<script>
import {mapGetters} from "vuex";
import UpdateCoupon from "@/components/Admin/Coupons/UpdateCoupon"
export default {
     components: { UpdateCoupon, },
     computed: {
          ...mapGetters({
                coupon: 'adminCoupons/coupon',
          }),
     },
     beforeMount() {
          if(!this.coupon || this.coupon.coupon_uid != this.$route.params.id){
               this.getCoupon(this.$route.params.id)
          }else{
               this.loading = false;
          }
     },
     data: () => ({
          loading: true,
          edit: false
     }),
     methods:{
          getCoupon(uid){
              this.$store.dispatch('adminCoupons/getCoupon',uid)
              this.loading = false
          },
          amount(discount_amount,type){
               if(type == 'fixed'){
                    return discount_amount+" €"
               }else if(type == 'percentage'){
                    return discount_amount+" %"
               }
          }
     }
}
</script>