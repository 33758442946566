<template>
     <v-card :loading="loading">
          <v-card-text>
              <v-row>
                        <v-col class="mt-5" cols="8"  >
                              <v-text-field
                                   outlined
                                   v-model="name"
                                   dense
                                   class="text-uppercase"
                                   @keyup.enter="addFilters"
                                   name="name"
                                   :label="$t('attributes.store_name.label')"
                                   prepend-inner-icon="mdi-store"
                              ></v-text-field>
                         </v-col>
                         <v-col class="mt-5" cols="4"  >
                              <v-text-field
                                   outlined
                                   v-model="city"
                                   dense
                                   class="text-uppercase"
                                   @keyup.enter="addFilters"
                                   name="city"
                                   :label="$t('attributes.city.label')"
                                   prepend-inner-icon="mdi-city"
                              ></v-text-field>
                         </v-col>
              </v-row>
          </v-card-text>
     </v-card>
</template>
<script>
export default {
     name: 'filters',
     props:{
          filters:{
               type: Object,
               required: false,
          },
          meta:{
               type: Object,
               required: true
          },
          loading:{
               type: Boolean,
               required: false
          },
          stores:{
               type: Array,
               required: false
          },
     },
     data: () => ({
          name: null,
          city: null,
     }),
     methods:{
          get(){
               this.meta.current_page = 1
               this.$emit('update:meta',this.meta)
               this.$emit('change')
          },
          addFilters(event){
               console.log('event addFilters',event)

               var value = event.target.value;
               var name = event.target.name;
               
               var type = "and";

               console.log({'name':name,'value':value,'type':type})
              
                    var newhere = {'type':type,'column':name,'is':'like','value':'%'+value+'%',config:"text"}
                    this.filters.wheres = [];
                    this.filters.wheres.push(newhere)
                    this.get();
               
          },
          removeFilters(){
               this.filters.wheres = [];
               this.get();
          }
     }
}
</script>