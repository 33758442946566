<template>
     <div>
     <v-container class="my-5" >
          <v-row>
               <v-col cols="12">
                   <v-card :loading="loading">
                        <v-card-title primary-title v-if="analytics">
                             <h3>{{ title }}</h3>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text  v-if="analytics">
                             <p>{{ description }}</p>
                             <datepicker 
                              label="FROM"
                              :max="range.end"
                              min="2021-05-01"
                              :date.sync="range.start"
                             ></datepicker>
                             <datepicker 
                              label="TO"
                              :min="range.start"
                              :max="this.$moment().format('YYYY-MM-DD')"
                              :date.sync="range.end"
                             ></datepicker>
                               <v-spacer></v-spacer>
                              <v-btn block @click="getAnalytics" outlined color="primary">get</v-btn>
                        </v-card-text>
                   </v-card>
               </v-col>
               <v-col cols="12">
                   <v-card :loading="loading">
                        <v-card-title primary-title  v-if="analytics">
                            {{title}}
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text v-if="chartData">
                             <p> {{ range.start | moment(" D MMMM YYYY") }} - {{ range.end | moment(" D MMMM YYYY") }}</p>
                             <apexchart :key="loading" width="100%" type="line" :options="chartOptions" :series="chartData"></apexchart>
                        </v-card-text>
                   </v-card>
               </v-col>
          </v-row>
     </v-container>
     </div>
</template>


<script>
import datepicker from '@/components/Commons/FormValues/datepicker.vue';
import {mapGetters} from "vuex";

export default {
     components: { 
          datepicker
     },
     computed: {
          ...mapGetters({
                analytics: 'adminAnalytics/single',
          }),
     },
     mounted() {
          this.range.start = this.$moment().subtract(30,'Days').format('YYYY-MM-DD')
          this.range.end = this.$moment().format('YYYY-MM-DD')
          this.slug = this.$route.params.id;
          this.title = this.$route.params.id;
          this.description = this.$route.params.id;

          if(!this.analytics){
               this.getAnalytics()
          }else if(this.analytics[0].slug != this.$route.params.id){
               this.getAnalytics()
          }else{
               this.splitValues(this.analytics)
               this.loading = false;
          }
     },
     data: () => ({
          loading: true,
          slug: null,
          title: null,
          description: null,
          range: {
               start: null,
               end: null,
          },
          labels: [],
          values: [],
          chartData: null,
          chartOptions: null,
     }),
     methods:{
          async getAnalytics(){
               var params = {
                    'table': this.$route.params.id,
                    'range': this.range,
               }
               this.loading = true
               this.chartData = null,
               this.chartOptions = null,
               this.labels =  [],
               this.values = [],

              this.$store.dispatch('adminAnalytics/getSingleAnalytics',params)
              .then((response) => {
                   console.log(response)
                   this.splitValues(this.analytics)

              })
          },
          splitValues(data){

               data.forEach(element => {
                    // this.labels.push(this.$moment(element.created_at).format('L'))
                    this.values.push({
                         x: this.$moment(element.created_at),
                         y: element.daily,
                    })
               });
               
               var measure = (data[0].measure_unit) ? data[0].measure_unit : ''
               if(measure){
                    measure = this.$t("analytics.measure_unit."+measure)
               }


               this.title = data[0].title;
               this.description = data[0].description;
                    

               this.chartOptions = {
                    chart: {
                         type: 'area',
                         stacked: false,
                         height: 350,
                         zoom: {
                              type: 'x',
                              enabled: true,
                              autoScaleYaxis: true
                         },
                         toolbar: {
                              autoSelected: 'zoom'
                         }
                    },
                    title:{
                         text: this.title
                    },
                    subtitle:{
                         text: this.description
                    },
                    xaxis: {
                         type: 'datetime',
                    },
                    colors: ['#546E7A'],
                    markers: {  
                         size: [4, 7],
                         colors: [ '#b2152cad' ],
                    },
                    stroke: {
                      curve: 'smooth',
                    },
                    dataLabels: {
                         enabled: false,
                         formatter: function (val) {
                              return  val.toLocaleString() + " " + measure
                         },
                    },
                    tooltip : {
                         y: {
                              formatter: function (val) {
                              return val.toLocaleString() +' '+ measure
                              }
                         }
                    },
               }

               this.chartData = [{
                    name: this.title,
                    data: this.values
               }]

               console.log(this.chartData)

               this.loading = false
                
          },
          
          
     }
}
</script>