<template>
     <v-row>
          <v-col cols="12">
               <h3>Manage User Roles</h3>
          </v-col>
          <v-col>
               <v-list
               subheader
               two-line
               flat
               >
                    <v-list-item-group
                         multiple
                    >
                         <template  v-for="item in allRoles">
                         <v-list-item :key="item" @click="toggleRoleUser(item)" >
                              <v-list-item-action>
                                   <v-checkbox
                                   :input-value="roles.includes(item)"
                                   color="primary"
                                   ></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                   <v-list-item-title>{{$t('roles.'+item+'.title')}}</v-list-item-title>
                                   <v-list-item-subtitle>{{$t('roles.'+item+'.description')}}</v-list-item-subtitle>
                              </v-list-item-content>
                         </v-list-item>
                         </template>
                    </v-list-item-group>
               </v-list>
          </v-col>
     </v-row>
</template>

<script>
export default {
     name:"UserRoles",
     props:{
          roles:{
               type: Array,
               required: true
          },
          uid:{
               type: String,
               required: true,
          }
     },
     beforeMount(){
          
     },
     data: () => ({
          allRoles:['user','manager','admin'] // solo i modificabili (admin non assegnabile per ora)
     }),
     methods:{
          toggleRoleUser(role){
               var query = {}
               query.uid = this.uid
               query.action = 'null'
               query.role = role

               if(this.roles.includes(role)){
                    query.action = 'remove'
               }else{
                    query.action = 'assign'
               }
               this.$store.dispatch('adminUsers/updateRoles',query)
          },
          
     }
}
</script>