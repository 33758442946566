<template>
     <div>
          <v-container class="my-5" >
          <v-row>
               <v-col cols="12" v-if="message">
                    <v-alert type="error">
                         {{message}}
                         <ul  v-if="Object.keys(errors).length">
                              <li v-for="(err,field) in errors" :key="field">
                                   <b>{{field}}</b>: <span v-for="(e,i) in err" :key="i" >{{e}}</span>
                              </li>  
                         </ul>
                    </v-alert>
               </v-col>
               <v-col cols="12" v-if="user">
                    <v-alert 
                         outlined
                         prominent
                         text
                         type="error" 
                          color="danger" 
                          v-if="user.deleted_at"
                         >
                         <h3>
                         THIS USER HAS BEEN DELETED AT: 
                         {{ [ user.deleted_at ] | moment("dddd, D MMMM YYYY") }}
                         </h3>
                         <b>{{$t('attributes.uid.label')}}</b>: {{ user.uid}}

                    </v-alert>
                    <view-profile v-if="!user.deleted_at" :loading="loading" :isAdmin="isAdmin" :user="user" :key="user.uid"></view-profile>
               </v-col>
               <v-col cols="12"  >
                   <v-card :loading="loading" >
                        <v-card-actions v-if="user">
                         <v-row class="text-center" v-if="!user.deleted_at">
                             <v-col class="my-2">
                             <dialog-simple :button="$t('commons.edit')+' '+$t('commons.points')" :title="$t('commons.points')" v-if="isAdmin">
                                  <slot>
                                       <form-user-points :uid="user.uid" :key="user.uid" :points="user.points"></form-user-points>
                                  </slot>
                             </dialog-simple>
                             </v-col>
                             <v-col class="my-2">
                             <dialog-simple :button="$t('commons.edit')+' '+$t('commons.roles')" :title="$t('commons.roles')" v-if="isAdmin">
                                  <slot>
                                       <user-roles v-if="user" :uid="user.uid" :key="user.uid" :roles="user.roles"></user-roles>
                                  </slot>
                             </dialog-simple>
                             </v-col>
                             <v-col class="my-2">
                             <dialog-simple width="900px" :button="$t('commons.edit')+' '+$t('commons.profile')" :title="$t('commons.profile')">
                                  <slot>
                                       <form-user-profile :uid="user.uid" :key="user.uid" :profile="user.profile"></form-user-profile>
                                  </slot>
                             </dialog-simple>
                             </v-col>
                             <v-col class="my-2">
                             <dialog-simple width="900px" :button="$t('commons.edit')+' '+$t('commons.privacy')" :title="$t('user.privacy')" >
                                  <slot>
                                       <form-user-privacy :uid="user.uid" :privacy="user.privacy"></form-user-privacy>
                                  </slot>
                             </dialog-simple>
                             </v-col>
                             <v-col class="my-2">
                             <dialog-simple width="900px" :button="$t('commons.edit')+' '+$t('commons.email')" :title="$t('commons.email')" >
                                  <slot>
                                       <form-user-settings :uid="user.uid" :key="user.uid" :email="user.email"></form-user-settings>
                                  </slot>
                             </dialog-simple>
                             </v-col>
                             <v-col class="my-2">
                             <dialog-simple width="900px" :button="$t('commons.edit')+' '+$t('commons.phone')" :title="$t('commons.phone')" >
                                  <slot>
                                      <form-user-settings :uid="user.uid" :key="user.uid" :phone="user.phone"></form-user-settings>
                                  </slot>
                             </dialog-simple>
                             </v-col>
                         </v-row>
                        </v-card-actions>
                   </v-card>
               </v-col>
               <v-col cols="12" v-if="user">
                   <v-card :loading="loading"  v-if="!user.deleted_at">
                        <v-card-text>
                             <v-tabs
                                   v-model="tab"
                                   centered
                                   grow
                                   color="primary"
                              >
                                   <v-tab>
                                   {{ $t('commons.active') }} {{ $t('commons.coupons') }}
                                   </v-tab>
                                   <v-tab>
                                   {{ $t('commons.completed') }} {{ $t('commons.coupons') }}
                                   </v-tab>
                                   <v-tab v-if="isAdmin">
                                   {{ $t('commons.receipts') }}
                                   </v-tab>
                                   <v-tab v-if="isAdmin">
                                   {{ $t('commons.stores') }}
                                   </v-tab>
                             </v-tabs>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text>
                             <v-tabs-items v-model="tab" v-if="user">
                                   <v-tab-item
                                   transition="fade"
                                   reverse-transition="fade"
                                   >
                                        <h2>ACTIVE COUPONS</h2>
                                        <coupons-admin-list  v-if="isAdmin"   :key="user.uid" :user="user"></coupons-admin-list>
                                        <coupons-list  v-else   :key="user.uid" :uid="user.uid"></coupons-list>
                                        
                                   </v-tab-item>

                                   <v-tab-item
                                        transition="fade"
                                        reverse-transition="fade"
                                   >
                                        <h2>COMPLETED COUPONS</h2>
                                        <completed-coupons-list v-if="user" :key="user.uid" :uid="user.uid"></completed-coupons-list>
                                   </v-tab-item>

                                   <v-tab-item
                                   transition="fade"
                                   v-if="isAdmin"
                                   reverse-transition="fade"
                                   >  
                                        <h2>RECEIPTS</h2>
                                        <receipts-list v-if="user" :key="user.uid" :uid="user.uid"></receipts-list>   
                                   </v-tab-item>

                                   <v-tab-item
                                   transition="fade"
                                   reverse-transition="fade"
                                   >
                                        <h2>STORES</h2>
                                        <stores-list  v-if="user" :key="user.uid" :uid="user.uid"></stores-list>
                                   </v-tab-item>
                             </v-tabs-items>
                        </v-card-text>  
                   </v-card>
               </v-col>
          </v-row>
          </v-container>
     </div>
</template>


<script>
import {mapGetters} from "vuex";
import ViewProfile from '@/components/User/Profile/ViewProfile.vue';
import UserRoles from '@/components/User/Roles/UserRoles.vue';
import DialogSimple from '@/components/Commons/DialogSimple.vue';
import FormUserPoints from '@/components/User/Profile/FormUserPoints.vue';
import FormUserProfile from '@/components/User/Profile/FormUserProfile.vue';
import FormUserPrivacy from '@/components/User/Profile/FormUserPrivacy.vue';
import FormUserSettings from '@/components/User/Profile/FormUserSettings.vue';
import CouponsList from '@/components/User/Coupons/CouponsList.vue';
import CouponsAdminList from '@/components/User/Coupons/CouponsAdminList.vue';
import ReceiptsList from '@/components/User/Receipts/ReceiptsList.vue';
import StoresList from '@/components/User/Stores/StoresList.vue';
import CompletedCouponsList from '@/components/User/CompletedCoupons/CompletedCouponsList.vue';

export default {
     components: { 
          ViewProfile,
          UserRoles,
          DialogSimple,
          FormUserPoints,
          FormUserProfile,
          FormUserPrivacy,
          CouponsList,
          ReceiptsList,
          StoresList,
          FormUserSettings,
          CompletedCouponsList,
          CouponsAdminList
     },
     computed: {
          ...mapGetters({
                user: 'adminUsers/user',
                isAdmin: 'auth/isAdmin',
          }),
     },
     mounted() {
               this.loading = true;
          if(!this.user){
                this.getUser(this.$route.params.id)
          } else if( this.user.uid != this.$route.params.id){
               this.getUser(this.$route.params.id)
          }else{
               this.loading = false;
          }
     },
     created() {
     this.$watch(
          () => this.$route.params,
               (newid, oldid) => {
                   if(newid != oldid){
                        this.loading = true;
                         this.getUser(newid.id)
                   }
               }
          )
     },
     data: () => ({
          loading: true,
          tab:'coupons',
          message: null,
          errors: {}
     }),
     methods:{
         async getUser(uid){
              this.$store.dispatch('adminUsers/getUser',uid)
              .then((response) => {
                    console.log('getUser success!',response)
                    this.loading = false
               })
               .catch((err) => {
                    this.loading = false
                    this.message = err.response.data.message
                    this.errors = (err.response.data.errors) ? err.response.data.errors : {}
               })
          },
     }
}
</script>