<template>
     <div>
          <apexchart 
               v-if="options"
               ref="chart"
               width="100%" 
               type="bar" 
               :options="options" 
               :series="series">
          </apexchart>
     </div>
</template>

<script>

export default {

     props:{
          table :{
               type: Object,
               required: true,
          },
          labels:{
               type: Array,
               required: false,
               default: () => ([
                    'daily',
                    'yesterday',
                    'weekly',
                    'last_week',
                    'monthly',
                    'last_month',
                    'annual',
                    'total',
               ]),
          }
     },
     data: () => ({
          options: null,
          chartOptions: {
               colors:['#b2152cad'],
               title:{
                    text: "Chart"
               },
               subtitle:{
                    text: "Chart description"
               },
               chart: {
                    type: 'bar',
               },
               plotOptions: {
                    bar: {
                         barHeight: '80',
                         distributed: true,
                         horizontal: true,
                         borderRadius: 10,
                    },
                    
               },
               dataLabels: {
                    enabled: false,                    
               },
               legend: {
                    show: false,
               }
          },
          series: [{
               name: "Serie",
               data: []
          }],
     }),
     mounted(){
          console.log("mounted chart BAr", this.table);

          Object.keys(this.table).forEach((key) => {
               if(this.labels.includes(key)){

                    this.series[0].data.push({
                         x: this.$t("analytics.labels."+key),
                         y: this.table[key],
                    })
               }
          });

          this.options = this.chartOptions;

          this.options.title.text = this.table.title;
          this.options.subtitle.text = this.table.description;
          var measure = (this.table.measure_unit) ? this.table.measure_unit : ''
          if(measure){
               measure = this.$t("analytics.measure_unit."+measure)
          }
          this.options.dataLabels = {
               enabled: true,
               textAnchor: 'start',
               offsetX: 20,
               style: {
                         colors: ['#010101']
               },
               formatter: function (val) {
                return  val.toLocaleString() + " " + measure
              },
          }

          this.options.tooltip = {
              y: {
                formatter: function (val) {
                  return val.toLocaleString() +' '+ measure
                }
              }
          },

          this.options.xaxis = {
               labels: {
                formatter: function (val) {
                  return val.toLocaleString() +' '+ measure
                }
              }
          },
          
          this.series[0].name = this.table.title;
          // console.log(this.series);

     },
     methods:{
          formatter(val){
               if(this.table.measure_unit){
                    return val + this.table.measure_unit
               }
          }
     }

}

</script>