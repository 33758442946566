<template>
     <v-card :loading="loading">
          <v-card-text>
              <v-row>
                        <v-col cols="4">
                              <v-text-field
                                   outlined
                                   v-model="email"
                                   dense
                                   clearable
                                   class="text-uppercase"
                                   @change="addFilters({name: 'email', value: email})"
                                   name="email"
                                   :label="$t('attributes.email.label')"
                                   prepend-inner-icon="mdi-email"
                              ></v-text-field>
                         </v-col>
                         <v-col cols="4" >
                              <v-text-field
                                   outlined
                                   v-model="phone"
                                   clearable
                                   dense
                                   class="text-uppercase"
                                   @change="addFilters({name: 'phone', value: phone})"
                                   name="phone"
                                   :label="$t('attributes.phone.label')"
                                   prepend-inner-icon="mdi-phone"
                              ></v-text-field>
                         </v-col>
                         <v-col cols="4" >
                              <v-text-field
                                   outlined
                                   v-model="uid"
                                   clearable
                                   dense
                                   class="text-uppercase"
                                   @change="addFilters({name:'uid', value: uid})"
                                   name="uid"
                                   :label="$t('attributes.uid.label')"
                                   prepend-inner-icon="mdi-ticket"
                              ></v-text-field>
                         </v-col>
                         <v-col cols="6">
                               <storepicker return="store_code" @change="addFilters"></storepicker>
                         </v-col>
                         <v-col>
                               <v-checkbox :label="$t('attributes.marketing.label')" class="mt-0"  @change="addFilters({name:'user_privacies.marketing', value:marketing})" v-model="marketing" name="marketing"></v-checkbox>
                         </v-col>
                         <v-col>
                               <v-checkbox :label="$t('attributes.user_confirm.label')" class="mt-0"  @change="addFilters({name:'user_privacies.user_confirm', value:user_confirm})" v-model="user_confirm" name="user_confirm"></v-checkbox>
                         </v-col>
              </v-row>
          </v-card-text>
          <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn @click="removeFilters" outlined color="primary">
                    <v-icon>mdi-close</v-icon> {{ $t('commons.delete') }} {{ $t('commons.filters') }}
               </v-btn>
          </v-card-actions>
     </v-card>
</template>
<script>
import storepicker from "@/components/Commons/FormValues/storepicker"

export default {
     name: 'filters',
      components: {
          storepicker
     },
     props:{
          filters:{
               type: Object,
               required: false,
          },
          meta:{
               type: Object,
               required: true
          },
          loading:{
               type: Boolean,
               required: false
          },
     },
     data: () => ({
          email: null,
          uid: null,
          phone: null,
          marketing: null,
          user_confirm: null,
     }),
     methods:{
          get(){
               this.meta.current_page = 1
               this.$emit('update:meta',this.meta)
               this.$emit('change')
          },
          checkSameWheres(name){
               console.log("Checking",name)

               this.filters.wheres.forEach(where => {
                    console.log(where.column)
                    if(where.column == name){
                         console.log("WHERE NAME SAME",[where, name])
                         this.filters.wheres.splice(this.filters.wheres.indexOf(where),1) 
                    }
               });
          
          },
          addFilters(event){

               var value = null;
               var name = null;

               if(event.target){
                    value = event.target.value;
                    name = event.target.name;
               }else{
                    value = event.value;
                    name = event.name;
               }
             
               var type = "and";
               var is = 'like';
               
               this.checkSameWheres(name);

               if(name == 'user_privacies.marketing'){
                    if(!this.marketing){
                         this.removeFilters();
                         return 0
                    }
                    is = '=';
                    value = 1;
               }else if(name == 'user_privacies.user_confirm'){
                    if(!this.user_confirm){
                         this.removeFilters();
                         return 0
                    }
                    is = '<=';
                    value = new Date().toISOString().slice(0, 10);
               }

               if(is == 'like' && value != null){
                    value = '%'+value+'%';
               }

               if(value != null){
                    var newhere = {'type':type,'column':name,'is':is,'value':value, config:"text"}
                    this.filters.wheres.push(newhere)
               }else{
                    this.removeFilters();
               }

               var hasTable = name.split('.');
               if(hasTable.length >= 2){
                    if(hasTable[0] != this.table){
                         this.addTable(hasTable[0]);
                    }
               }

               console.log(this.filters);
               this.get();
               
          },
          addTable(table){
               if(!this.filters.tables){
                 this.filters.tables = [];
               }

               var FOUND = this.filters.tables.find(function(t) {
                    if(t.table == table)
                         return true;
               });

               if(!FOUND){
                    if(table == 'store_user'){
                         this.filters.tables.push({'table':table,'on':'user_id','with':'id'}) 
                    }else{
                         this.filters.tables.push({'table':table,'on':'user_uid','with':'uid'})             
                    }
               }

          },
          removeFilters(){
               this.email = null;
               this.uid = null;
               this.phone = null;
               this.marketing = null;
               this.user_confirm = null;
               this.filters.wheres = [];
               this.get();
          }
     }
}
</script>