<template>
     <div>
          <v-container class="my-5" >
          <v-row>

               <v-col cols="12" v-if="message">
                    <v-alert type="error">
                         {{message}}  
                    </v-alert>
               </v-col>
               <v-col cols="12">
                   <v-card :loading="loading">
                        <v-card-title primary-title v-if="receipt">
                             <div>
                                  <h3>{{receipt.receipt_uid}}</h3>
                             </div>
                        </v-card-title>
                   </v-card>
               </v-col>
               <v-col cols="12" md="6">
                   <v-card :loading="loading">
                        <v-card-title primary-title>
                            {{ $t('commons.receipt') }}
                        </v-card-title>
                        <v-card-text v-if="receipt">
                             <simple-table :table="{
                                  receipt_uid: receipt.receipt_uid,
                                  number: receipt.number,
                                  amount: receipt.amount +' '+ receipt.currency_symbol,
                                  points: receipt.receipt_points,
                                  issued_on: receipt.issued_on
                             }" ></simple-table> 
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions v-if="receipt">
                             <dialog-simple width="800px" :button="$t('commons.delete')" :title="$t('commons.delete')+' '+$t('commons.receipt')">
                                  <slot>
                                       <delete-receipt :receipt="receipt"></delete-receipt>   
                                  </slot>
                             </dialog-simple>
                        </v-card-actions>
                   </v-card>
               </v-col>
               <v-col cols="12" md="6">
                   <v-card :loading="loading" class="mb-3">
                        <v-card-title primary-title>
                            {{ $t('commons.store') }}
                        </v-card-title>
                        <v-card-text v-if="receipt">
                             <v-list-item
                                  v-for="store in [receipt.store]"
                                  :key="store.store_uid"
                                  :to="{'name':'admin.stores.single', 'params':{'id':store.store_uid}}"
                             >
                                   <v-list-item-icon>
                                   <v-icon>mdi-store</v-icon>
                                   </v-list-item-icon>
                                   <v-list-item-content>
                                        <v-list-item-title>{{store.name}}</v-list-item-title>
                                         <v-list-item-subtitle>{{store.address}}</v-list-item-subtitle>
                                   </v-list-item-content>
                             </v-list-item>
                        </v-card-text>
                   </v-card>
               
                   <v-card :loading="loading">
                        <v-card-title primary-title>
                            {{ $t('commons.user') }}
                        </v-card-title>
                        <v-card-text v-if="receipt">
                              <div v-if="receipt.user">
                              <v-list-item
                                   v-for="user in [receipt.user]"
                                   :key="user.uid"
                                   :to="{'name':'admin.users.single', 'params':{'id':user.uid}}"
                              >
                                        <v-list-item-icon>
                                        <v-icon>{{ $t('roles.user.icon') }}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                             <v-list-item-title>{{user.fullname}}</v-list-item-title>
                                             <v-list-item-subtitle>{{user.email}}</v-list-item-subtitle>
                                        </v-list-item-content>
                              </v-list-item>
                              </div>
                              <div v-else>
                                   <v-alert type="warning" :value="true">
                                        NO USER FOUND IN  THIS LIST
                                   </v-alert>
                              </div>
                        </v-card-text>
                   </v-card>
               </v-col>
          </v-row>
          </v-container>
     </div>
</template>


<script>
import {mapGetters} from "vuex";
import DialogSimple from '@/components/Commons/DialogSimple.vue';
import DeleteReceipt from '@/components/Admin/Receipts/DeleteReceipt';

export default {
     components: { 
        'dialog-simple' :  DialogSimple,
        'delete-receipt': DeleteReceipt
      },
     computed: {
          ...mapGetters({
                receipt: 'adminReceipts/receipt',
          }),
     },
     beforeMount() {
          if(!this.receipt || this.receipt.receipt_uid != this.$route.params.id){
               this.getreceipt(this.$route.params.id)
          }else{
               this.loading = false;
          }
     },
     data: () => ({
          loading: true,
          message: null,
     }),
     methods:{
          getreceipt(uid){
                    this.loading = true

              this.$store.dispatch('adminReceipts/getReceipt',uid)
              .then((response) => {
                    console.log('getReceipt success!',response)
                    this.loading = false
               })
               .catch((err) => {
                    this.loading = false
                    this.receipt = null
                    this.message = err.response.data.message
               })
          }
     }
}
</script>