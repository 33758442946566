<template>
     <v-container @dragover="dragover" @dragleave="dragleave" @drop="drop">
          <v-row>
               <v-col cols="12">
                    <h3>{{$t('commons.uploader')}} {{$t('commons.receipts')}}</h3>
                    <p>
                         header: issued_on, store_code, user_uid, amount, number<br>
                         <a target="_blank" href="/docs/examples/csv-sample.csv">download example</a>
                    </p>
               </v-col>
               <v-col cols="12">
                    <v-alert v-if="message" :type="alert" :value="true" dismissible>
                        {{message}}
                    </v-alert>
               </v-col>
               <v-col cols="12">
                     <v-form
                         ref="form"
                         v-model="valid"
                         lazy-validation
                         :loading="loading"
                    >
                    <v-file-input
                         :disabled="loading"
                          :accept="types"
                          required
                          :rules="rules"
                          show-size
                         outlined
                         dense
                          @change="onChange()"
                          @click:clear="remove()"
                         v-model="file"
                         :label="$t('attributes.filecsv.label')+' '+((file)?file.type:'')"
                         :placeholder="$t('attributes.filecsv.placeholder')"
                         :error-messages="errors.file"
                    ></v-file-input>
                    <v-btn :disabled="!valid" :loading="loading" @click="uploadFile" block color="primary">UPLOAD</v-btn>
                    </v-form>
               </v-col>
               <v-col cols="12">
                    <v-alert v-if="loading" :type="'info'" :value="true">
                      DONT LEAVE THIS PAGE
                    </v-alert>

                    <v-alert v-if="Object.keys(errors).length" :type="alert" :value="true" colored-border border="top" class="mt-4" elevation="2">
                         <ul>
                            <li v-for="(err,field) in errors" :key="field">
                              <b>{{field}}</b>: <span v-for="(e,i) in err" :key="i" >{{e}}<br></span>
                            </li>  
                         </ul>
                    </v-alert>
               </v-col>
               
          </v-row>
     </v-container>
</template>
<script>
var types = ['text/csv','text/plain', 'application/csv', 'application/x-csv', 'text/csv', 'text/comma-separated-values', 'text/x-comma-separated-values', 'text/tab-separated-values', 'application/vnd.ms-excel'];
export default {
     name: "UploaderCsv",
     data: () => ({
      file: null,
      valid: false,
      types: types,
      rules: [
        value => !!value  || 'REQUIRED!',
        value => !value || value.size < 1000000 || 'File size should be less than 2MB!',
        value => !value || types.includes(value.type)  || 'File should be includes in:'+types,
      ],
      loading: false,
      message: null,
      alert: null,
      errors: {}
     }),
     methods:{
          uploadFile(){
               
               this.valid = this.$refs.form.validate()

               if(this.valid){
                    this.loading = true
                    let formData = new FormData();
                    formData.append("file", this.file);
                    console.log('formData',formData);
                    this.$store.dispatch('adminReceipts/uploader',formData)
                    .then((response) => {
                         console.log('adminReceipts/uploader!',response)
                         this.message = response.data.message;
                         this.alert = 'success';
                         this.loading = false;
                         this.$refs.form.reset()
                    })
                    .catch((err) => {
                         console.log("ERRORE",err.response)
                         this.loading = false
                         this.message = err.response.data.message
                         this.alert = 'error';
                         this.errors = err.response.data.errors
                    })
               }

          },
          onChange() {
               // this.errors = {};
               // this.message = null;
               this.valid = this.$refs.form.validate()
          },
          remove() {
               this.file = [];
               this.errors = {};
               this.message = null;
               this.valid = this.$refs.form.validate()
          },
          dragover(event) {
               event.preventDefault();
               // Add some visual fluff to show the user can drop its files
               if (!event.currentTarget.classList.contains('dropzone')) {
               event.currentTarget.classList.add('dropzone');
               }
          },
          dragleave(event) {
               // Clean up
               event.currentTarget.classList.remove('dropzone');
          },
          drop(event) {
               event.preventDefault();
               this.file = event.dataTransfer.files[0];
               console.log('add',this.file)
               this.onChange(); // Trigger the onChange event manually
               // Clean up
               event.currentTarget.classList.remove('dropzone');
          }
     }
}
</script>

<style scoped>
.dropzone{
     background-color: rgba(100, 162, 100, 0.55);
     border: 3px dotted grey;
}
</style>