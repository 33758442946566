<template>
     <v-row>
          <v-col cols="12" v-if="loading">
               <v-progress-linear
                    indeterminate
                    color="primary"
               ></v-progress-linear>
          </v-col>
          <v-col cols="12" v-if="message && Object.keys(errors).length<1">
               <v-alert
                  type="success"
                  border="top"
                  colored-border
                  elevation="3"
                  dismissible
               >
               {{message}}
               </v-alert>
          </v-col>
          <v-col v-if="Object.keys(errors).length">
               <v-alert
                    border="top"
                    colored-border
                    type="error"
                    elevation="2"
               >
                    <ul>
                    <li v-for="(err,field) in errors" :key="field">
                         <b>{{field}}</b>: <span v-for="(e,i) in err" :key="i" >{{e}}</span>
                    </li>  
                    </ul>
               </v-alert>
          </v-col>
          <v-col cols="6">
               <v-switch
                    class="mt-1"
                    v-model="coupon.active"
                    :label="$t('attributes.active.label')"
                    inset
                    color="success"
                    :messages="$t('attributes.active.hint')"
                >
                </v-switch>
          </v-col>
          <v-col cols="6">
               <v-switch
                    class="mt-1"
                    v-model="coupon.autoadd"
                    :label="$t('attributes.autoadd.label')"
                    inset
                    color="success"
                    :messages="$t('attributes.autoadd.hint')"
                >
                </v-switch>
          </v-col>
          <v-col cols="12">
               <v-text-field
                    v-model="coupon.coupon_code"
                    :label="$t('attributes.coupon_code.label')"
                    :placeholder="$t('attributes.coupon_code.placeholder')"
                    :hint="$t('attributes.coupon_code.hint')"
                    :error-messages="errors.coupon_code"
                    
               ></v-text-field>
               <v-text-field
                    v-model="coupon.title"
                    :label="$t('attributes.title.label')"
                    :placeholder="$t('attributes.title.placeholder')"
                    :hint="$t('attributes.title.hint')"
                    :error-messages="errors.title"
                    
               ></v-text-field>
               <v-text-field
                    v-model="coupon.description"
                    :label="$t('attributes.description.label')"
                    :placeholder="$t('attributes.description.placeholder')"
                    :hint="$t('attributes.description.hint')"
                    :error-messages="errors.description"
                    
               ></v-text-field>
          </v-col>
          <v-col cols="6">
              <datepicker 
                                   :label="$t('attributes.start.label')"
                                   :placeholder="$t('attributes.start.placeholder')"
                                   :date.sync="coupon.start"
                                   :hint="'min 18'"
                                   :max="coupon.end"
                                   :errors="errors.start"
               ></datepicker>
          </v-col>
          <v-col cols="6">
              <datepicker 
                                   :label="$t('attributes.end.label')"
                                   :placeholder="$t('attributes.end.placeholder')"
                                   :date.sync="coupon.end"
                                   :hint="'min 18'"
                                   :min="coupon.start"
                                   :errors="errors.end"
               ></datepicker>
          </v-col>
          <v-col cols="3">
              <v-switch
                    class="mt-1"
                    v-model="coupon.replicable"
                    :label="$t('attributes.replicable.label')"
                    inset
                    color="success"
                    :messages="$t('attributes.replicable.hint')"
                >
                </v-switch>
          </v-col>
          <v-col cols="9">
                <v-text-field
                    type="number"
                    prepend-icon="mdi-time"
                    v-model="coupon.expire_in_days"
                    :label="$t('attributes.expire_in_days.label')"
                    :placeholder="$t('attributes.expire_in_days.placeholder')"
                    :hint="$t('attributes.expire_in_days.hint')"
                    :error-messages="errors.expire_in_days"
               ></v-text-field>
          </v-col>
          <v-col cols="6">
               <v-select
                    v-model="coupon.type"
                    :items="coupon_types"
                    :label="$t('attributes.type.label')"
                    return-object
                    :error-messages="errors.type"
                    dense
               ></v-select>
          </v-col>
          <v-col cols="6">
               <v-select
                    v-model="coupon.valid_levels"
                    :items="valid_levels"
                    :label="$t('attributes.valid_levels.label')"
                    multiple
                    :error-messages="errors.valid_levels"
               ></v-select>
          </v-col>
          <v-col cols="6">
               <v-text-field
                    type="number"
                    :prepend-icon="(coupon.type == 'percentage') ? 'mdi-percent' : 'mdi-currency-eur'"
                    v-model="coupon.discount_amount"
                    :label="$t('attributes.discount_amount.label')"
                    :placeholder="$t('attributes.discount_amount.placeholder')"
                    :hint="$t('attributes.discount_amount.hint')"
                    :error-messages="errors.discount_amount"
               ></v-text-field>
          </v-col>
          <v-col cols="6">
               <v-text-field
                    type="number"
                    min="0"
                    max="1000"
                    v-model="coupon.points"
                    :label="$t('attributes.points.label')"
                    :placeholder="$t('attributes.points.placeholder')"
                    :hint="$t('attributes.points.hint')"
                    :error-messages="errors.points"
               ></v-text-field>
          </v-col>
          <v-col cols="12">
               <v-btn :loading="loading" block @click="updateCoupon" color="primary"><v-icon>mdi-save</v-icon> SAVE</v-btn>
          </v-col>
     </v-row>
</template>

<script>
import datepicker from '@/components/Commons/FormValues/datepicker.vue';

export default {
     name: "UpdateCoupon",
     components:{
          datepicker
     },
     props:{
          coupon:{
               type:Object,
               required: true,
          }
     },
     data: () => ({
          loading: null,
          errors:{},
          message:null,
          coupon_types: ['fixed','percentage'],
          valid_levels: ['bronze','silver','gold']
     }),
     methods:{
          updateCoupon(){
               this.loading = true
               this.message = null
               console.log('Update',this.coupon)

               this.$store.dispatch('adminCoupons/updateCoupon',this.coupon)
                    .then((response) => {
                         this.message = this.$t("commons.saved")
                         console.log('CREATE success!',response)
                         this.loading = false;
                    })
                    .catch((err) => {
                         console.error('CREATE ERROR!',err)
                         this.message = err.response.data.message
                         this.errors = err.response.data.errors
                         this.loading = false;
                    }) 

          }
     }
}
</script>
