<template>
     <v-container class="my-5" >
          <v-row>
               <v-col cols="12">
                   <v-card :loading="loading">
                        <v-card-title primary-title>
                            <h1>{{$t('admin.exports.title')}} </h1>
                              
                         <v-spacer></v-spacer>
                         <v-btn color="primary" outlined @click="getExports">reload</v-btn>
                    </v-card-title>
                    <v-card-text>
                         {{$t('admin.exports.description')}}
                    </v-card-text>
                   </v-card>
               </v-col>
               <v-col cols="12">
                    <v-card :loading="loading">
                        <v-card-text>
                         <paginated-table 
                           :headers="headers" 
                           :table="exportslist" 
                           id="id" 
                           :meta="meta"
                           :loading="loading"
                           download="url"  
                           name="admin.exports.single"
                           @change="getExports"
                           @playOrPause="playOrPause"
                         >
                         </paginated-table>
                        </v-card-text>
                   </v-card>
               </v-col>
          </v-row>
     </v-container>
</template>
<script>
import {mapGetters} from "vuex";

export default {
     computed: {
     ...mapGetters({
          exportslist: 'adminExports/exportslist',
          meta: 'adminExports/meta',
          errors: 'adminExports/errors',
          loading: 'loading/loading'
      }),
     },
     components:{
     },
     data: () => ({
          interval: 0,
          headers:[
               {text:'attributes.id.label', value: 'id', sortable: false},
               {text:'attributes.created_at.label', value: 'created_at', sortable: false},
               {text:'attributes.title.label', value: 'title_description', sortable: false},
               {text:'attributes.resources_type.label', value: 'resources_type', sortable: false, align: 'center'},
               {text:'attributes.status.label', value: 'status', sortable: false, align: 'center'},
               {text:'attributes.view.label', value: 'view', sortable: false, align: 'center'},
          ],
     }),
     beforeMount() {    
          this.getExports()
     },
     beforeDestroy(){
          clearInterval(this.interval)
     },
     methods:{
         async getExports(){
              this.$store.dispatch('adminExports/setMeta',this.meta)
              this.$store.dispatch('adminExports/getExports',this.filters).then(() => {
                    this.checkLoading() 
              })
         },
         playOrPause(item){
          console.log('playOrPause',item);
          this.$store.dispatch('adminExports/updateExport',item)
          .then(() => {
               this.getExports();
          })
         
         },
         checkLoading(){
          var result = this.exportslist.filter(obj => {
               return obj.status === 'loading'
          })
          clearInterval(this.interval);
          if(result.length > 0){
               console.log('result',result)
               var self = this;
               this.interval = setInterval(function() {
                    self.getExports()
               }, 60000);

          

               return true;
          }else{
               console.log('result FALSE',result)
               clearInterval(this.interval);
               return false;
          }
         }
     }
}
</script>