var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"my-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[(_vm.coupon)?_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.coupon.title))])]),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.coupon.coupon_code)+" ")],1):_vm._e(),(_vm.coupon)?_c('v-card-text',[_vm._v(" "+_vm._s(_vm.coupon.description)+" ")]):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.$t('commons.coupon'))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.edit = !_vm.edit}}},[(!_vm.edit)?_c('v-icon',[_vm._v("mdi-pencil")]):_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.coupon && !_vm.edit)?_c('v-card-text',[_c('simple-table',{attrs:{"table":{
                             coupon_code: _vm.coupon.coupon_code,
                             type: _vm.$t('commons.coupons_types.'+_vm.coupon.type),
                             discount_amount: _vm.amount(_vm.coupon.discount_amount,_vm.coupon.type),
                             points: '-'+_vm.coupon.points,
                             start: _vm.coupon.start,
                             end: _vm.coupon.end,
                             active: (_vm.coupon.active) ? true : false,
                             autoadd: (_vm.coupon.autoadd) ? true : false,
                             replicable: (_vm.coupon.replicable) ? true : false,
                             valid_levels: _vm.coupon.valid_levels,
                             expire_in_days: _vm.coupon.expire_in_days,
                             updated_at: _vm.coupon.updated_at,
                        }}})],1):_vm._e(),(_vm.edit)?_c('v-card-text',[_c('update-coupon',{attrs:{"coupon":_vm.coupon}})],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }