<template>
     <v-container class="my-5" >
          <v-row>
               <v-col cols="12">
                   <v-card :loading="loading">
                        <v-card-title primary-title>
                              <h1>{{$t('admin.users.title')}}</h1>
                              <v-spacer></v-spacer>
                              
                              <dialog-simple :button="$t('commons.export')+' '+$t('commons.users')" :title="$t('commons.export')+' '+$t('commons.users')" >
                                   
                                   <v-row>
                                        <v-col cols="12">
                                             <v-text-field 
                                                  type="text"
                                                  v-model="exportFile.title"
                                                  :label="$t('attributes.title.label')"
                                                  name="title"
                                                  required
                                                  :hint="$t('attributes.title.hint')"
                                                  :error-messages="errors.title"
                                             >
                                             </v-text-field>
                                             <v-text-field 
                                                  type="text"
                                                  v-model="exportFile.description"
                                                  :label="$t('attributes.description.label')"
                                                  name="description"
                                                  required
                                                  :hint="$t('attributes.description.hint')"
                                                  :error-messages="errors.description"
                                             >
                                             </v-text-field>
                                             <v-select
                                                  :items="['SIMPLE','MEDIUM','FULL']"
                                                  v-model="exportFile.resources_type"
                                                  :label="$t('attributes.resources_type.label')"
                                                  name="resources_type"
                                                  :hint="$t('attributes.resources_type.hint')"
                                                  :error-messages="errors.resources_type"
                                             >
                                             </v-select>
                                        </v-col>
                                        <v-col cols="12">
                                             Selected Fileds:
                                             <div v-if="exportFile.resources_type == 'FULL'">
                                                  "uid","email","phone","country","language","email_verified_at","phone_verified_at","points","level","title_name","firstname","lastname","gender","date_of_birth","age","city","zip_code","general","marketing","survey","third_party","user_confirm","registration_store_code","created_at"
                                             </div>
                                             <div v-if="exportFile.resources_type == 'MEDIUM'">
                                                  "uid","email","phone","country","language","points","level","title_name","firstname","lastname","marketing","user_confirm","created_at"
                                             </div>
                                             <div v-if="exportFile.resources_type == 'SIMPLE'">
                                                  "uid","email","phone","marketing","created_at"
                                             </div>
                                        </v-col>
                                        <v-col cols="12">
                                             <v-btn block @click="exportUsers" :loading="loading" color="success">
                                                  <v-icon class="mx-3">mdi-download</v-icon>
                                                  EXPORT USERS ON THIS FILTERS
                                             </v-btn>
                                        </v-col>
                                   </v-row>
                              
                              </dialog-simple>

                        </v-card-title>
                        <v-card-text>
                             {{$t('admin.users.description')}}
                        </v-card-text>
                        <v-card-text v-if="message ">
                             <v-alert :type="alertType">
                                  <v-row align="center">
                                   <v-col class="grow" cols="10">
                                        {{message}}
                                   </v-col>
                                   <v-col cols="2" class="shrink"  v-if="exported">
                                        <v-btn :to="{name:'admin.exports.list'}" color="dark">EXPORTS</v-btn>
                                   </v-col>
                                   </v-row>
                             </v-alert>
                        </v-card-text>
                   </v-card>
               </v-col>
               <v-col cols="12">
                    <simple-user-filters 
                         :meta="meta" 
                         :filters.sync="filters" 
                         :loading="loading" 
                         @change="getUsers">
                    </simple-user-filters>
               </v-col>
               <v-col cols="12">
                    <user-filters 
                              :loading="loading" 
                               :filters.sync="filters"
                               :meta="meta"
                               :columns="aviableFilters"
                               table="users"
                               @change="getUsers"
                    ></user-filters>
               </v-col>
               <v-col cols="12" v-if="errors.lenght > 0">
                   <v-card >
                        <v-card-title>
                              Errors
                        </v-card-title>
                        <v-card-text >
                             <v-alert type="error" v-if="message">
                                  <h4>{{message}}</h4>
                                  <ul>
                                       <li v-for="(values,error) in errors" :key="error">
                                            <strong>{{error}}</strong>: {{values[0]}}
                                       </li>
                                  </ul>
                             </v-alert>
                        </v-card-text>
                   </v-card>
               </v-col>
               <v-col cols="12" >
                   <v-card :loading="loading">
                        <v-card-text>
                         <paginated-table 
                           :headers="headers" 
                           :table="users" 
                           id="uid" 
                           :meta="meta"
                           :loading="loading"
                           view
                           name="admin.users.single"  
                           @change="getUsers"
                         ></paginated-table>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                         <dialog-simple :button="$t('commons.delete')+' '+$t('commons.sleepers')" :title="$t('commons.delete')+' '+$t('commons.sleepers')" >
                                   
                                   <v-row>
                                        <v-col cols="12">
                                             <v-text-field 
                                                  type="text"
                                                  v-model="sleeper.uid"
                                                  :label="$t('attributes.uid.label')"
                                                  name="uid"
                                                  required
                                                  :hint="$t('attributes.uid.hint')"
                                                  :error-messages="errors.uid"
                                             >
                                             </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                             <v-btn block @click="deleteSleeperUser" :loading="loading" color="danger">
                                                  <v-icon class="mx-3">mdi-delete</v-icon>
                                                  deleteSleeperUser
                                             </v-btn>
                                        </v-col>
                                        <v-col v-if="message ">
                                                  <v-alert :type="alertType">
                                                        {{message}}
                                                  </v-alert>
                                        </v-col>
                                   </v-row>
                              
                              </dialog-simple>
                        </v-card-actions>
                   </v-card>
               </v-col>
          </v-row>
     </v-container>
</template>
<script>
import {mapGetters} from "vuex";
import UserFilters from '@/components/User/UserFilters';
import SimpleUserFilters from '@/components/User/SimpleUserFilters';
import DialogSimple from '@/components/Commons/DialogSimple.vue';

export default {
     computed: {
     ...mapGetters({
          stores: 'adminStores/stores',
          users: 'adminUsers/users',
          errors: 'adminUsers/errors',
          meta: 'adminUsers/meta'
      }),
     },
     components:{
          'simple-user-filters': SimpleUserFilters,
          'user-filters': UserFilters,
          'dialog-simple': DialogSimple,
     },
     data: () => ({
          loading: false,
          headers:[
               {text:'attributes.country.label', value: 'country', sortable: false,align:"center"},
               {text:'attributes.uid.label', value: 'uid', sortable: false},
               {text:'attributes.active.label', value: 'privacy.user_confirm', sortable: false},
               {text:'attributes.points.label', value: 'points.total_points', sortable: false, align:"center"},
               {text:'attributes.email.label', value: 'email', sortable: false},
               {text:'attributes.fullname.label', value: 'profile.fullname', sortable: false},
              // {text:'attributes.roles.label', value: 'roles', sortable: false},
               {text:'attributes.created_at.label', value: 'created_at', sortable: false, align:"right"},
          ],
          aviableFilters: [
               {
                    text:"user_points.level", 
                    value:'user_points.level',
                    type:'select',
                    values:[{text:'gold',value:'gold'},{text:'silver',value:'silver'},{text:'bronze',value:'bronze'}]
               },
               {text:"user_points.total_points", value:'user_points.total_points'},
               {text:"users.uid", value:'users.uid'},
               {text:"users.email", value:'users.email'},
               {text:"users.phone", value:'users.phone'},
               {text:"users.created_at", value:'users.created_at'},
               {text:"user_profiles.city", value:'user_profiles.city'},
               {text:"user_profiles.zip_code", value:'user_profiles.zip_code'},
               {text:"user_privacies.general", value:'user_privacies.general'},
               {text:"user_privacies.marketing", value:'user_privacies.marketing'},
               {text:"user_privacies.survey", value:'user_privacies.survey'},
               {text:"user_privacies.user_confirm", value:'user_privacies.user_confirm'},
               // {
               //      text:"store_user.store_id", 
               //      value:'store_user.store_id',
               //      type:'select',
               //      values:[{text:'Triumph GETAFE',value:'1'},{text:'Triumph LAS ROSAZ',value:'2'}]
               // },
               // {
               //      text:"store_user.action", 
               //      value:'store_user.action',
               //      type:'select',
               //      values:[{text:'REGISTER',value:'REGISTER'},{text:'USE_COUPON',value:'USE_COUPON'},{text:'PURCHASE',value:'PURCHASE'},{text:'RETURN',value:'RETURN'}]
               // },
          ],
          message: null,
          exported: null,
          exportFile: {
               title: 'USER EXPORT',
               description: 'USER EXPORT DESCRIPTION',
               resources_type: 'SIMPLE'
          },
          title: 'USER EXPORT TITLE',
          description: 'USER EXPORT DESCRIPTION',
          filters: {wheres : []},
          sleeper:{
               uid: null,
          },
          alertType: 'warning'
     }),
     beforeMount() {
          this.getUsers()
          if(!this.stores.length){
               this.getStores()
          }else{
               this.addFilterStore(this.stores)
          }
     },
     mounted() {
          // window.setInterval(() => {
          //  this.getUsers()
          // }, 30000) //reload evry 30sec
     },
     methods:{
          addFilterStore(stores){

               var stores_value = []

                    stores.forEach(store => {
                         console.log('stores_value',store)
                         stores_value.push({text:store.name,value:store.store_code})
                    });
                    
                    this.aviableFilters.push({
                         text:"users.store_code", 
                         value:'users.store_code',
                         type:'select',
                         values: stores_value
                    })


          },
          async getStores(){
               this.loading = true;
                this.$store.dispatch('adminStores/getStores')
                .then((response) => {
                     this.loading = false
                     console.log(response)

                    this.addFilterStore(this.stores)
                    
                    
                })
                
          },
          async getUsers(){
              this.loading = true;
              this.$store.dispatch('adminUsers/setMeta', this.meta)
              this.$store.dispatch('adminUsers/getUsers', this.filters)
              .then((response) => {
                    this.loading = false
                    this.message = null
                    console.log(response)
              })
              .catch((err) => {
                    console.error('ERROR!',err)
                    this.message = err.response.data.message
                    this.loading = false;
               })
         },
         exportUsers(){
               this.loading = true;
               console.log('export on filters:', this.filters)
               
               this.message = "DON'T LEAVE THIS PAGE!";
               this.alertType = "info";
               var self = this;
             
               this.filters.title =  this.exportFile.title;
               this.filters.description =  this.exportFile.description;
               this.filters.resources_type =  this.exportFile.resources_type;
               
               this.$store.dispatch('adminUsers/exportUsers', this.filters)
               .then((response) => {
                    self.alertType = "success";
                    self.message = "EXPORT CREATE GO TO EXPORTS SECTION";
                    self.exported = response.data;
                    self.loading = false;

               });

               //  setTimeout(function () {
               //      self.loading = false;
               //      self.alertType = "success";
               //      self.message = "EXPORT CREATE GO TO EXPORTS SECTION";
               //      self.exported = true;
               //      console.log('loading',this.loading)
               // }, 2000);

              
         },
         deleteSleeperUser(){

          this.loading = true;

          var self = this;
          
          this.$store.dispatch('adminUsers/deleteSleeperUser', this.sleeper)
               .then((response) => {
                    
                    self.loading = false;
                    self.alertType = "success";
                    self.message = "USER SLEEPER DELETED";
                    self.sleeper.uid = null;
                    console.log(response);

               })
               .catch((errors) =>{
                    self.loading = false;
                    self.alertType = "error";
                    self.message = "USER SLEEPER Error ";
                    console.log(errors)
               });
         }
     }
}
</script>