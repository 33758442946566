<template>
     <v-container class="my-5" >
          <v-row>
               <v-col cols="12">
                   <v-card  :loading="loading">
                        <v-card-title primary-title>
                              <h1>{{$t('admin.receipts.title')}}</h1>
                              <v-spacer></v-spacer>
                              <dialog-simple :button="$t('commons.export')+' '+$t('commons.receipts')" :title="$t('commons.export')+' '+$t('commons.receipts')">
                                   <v-row>
                                        <v-col cols="12">
                                             <v-text-field 
                                                  type="text"
                                                  v-model="exportFile.title"
                                                  :label="$t('attributes.title.label')"
                                                  name="title"
                                                  required
                                                  :hint="$t('attributes.title.hint')"
                                                  :error-messages="errors.title"
                                                  @focus="errors.title = []"
                                             >
                                             </v-text-field>
                                             <v-text-field 
                                                  type="text"
                                                  v-model="exportFile.description"
                                                  :label="$t('attributes.description.label')"
                                                  name="description"
                                                  required
                                                  :hint="$t('attributes.description.hint')"
                                                  :error-messages="errors.description"
                                                  @focus="errors.description = []"
                                             >
                                             </v-text-field>
                                             <v-select
                                                  :items="['SIMPLE','MEDIUM','FULL']"
                                                  v-model="exportFile.resources_type"
                                                  :label="$t('attributes.resources_type.label')"
                                                  name="resources_type"
                                                  :hint="$t('attributes.resources_type.hint')"
                                                  :error-messages="errors.resources_type"
                                             >
                                             </v-select>
                                             <v-col cols="12">
                                             Selected Fileds:
                                             <div v-if="exportFile.resources_type == 'FULL'">
                                                  "track_code","country","issued_on","amount","currency","store_uid","store_code","store_name","uid","email","phone","language","email_verified_at","phone_verified_at","points","level","title_name","firstname","lastname","gender","date_of_birth","age","city","zip_code","general","marketing","survey","third_party","user_confirm","created_at","user_deleted_at"
                                             </div>
                                             <div v-if="exportFile.resources_type == 'MEDIUM'">
                                                  "track_code","country","issued_on","amount","currency","store_code","store_name","uid","email","phone",
                                             </div>
                                             <div v-if="exportFile.resources_type == 'SIMPLE'">
                                                  "issued_on","amount","store_name","uid","email","phone"
                                             </div>
                                        </v-col>
                                        </v-col>
                                        <v-col cols="12">
                                             <v-btn block @click="exportReceipts" :loading="loading" color="success">
                                                  <v-icon class="mx-3">mdi-download</v-icon>
                                             EXPORT Receipts ON THIS FILTERS
                                             </v-btn>
                                        </v-col>
                                   </v-row>
                              </dialog-simple>
                        </v-card-title>
                        <v-card-text>
                             {{$t('admin.receipts.description')}}
                             <v-spacer></v-spacer>
                             <dialog-simple :button="$t('commons.uploader')+' '+$t('commons.receipts')" :title="$t('commons.uploader')+' '+$t('commons.receipts')">
                                  <uploader-csv></uploader-csv>
                             </dialog-simple>
                        </v-card-text>
                        <v-card-text v-if="message ">
                             <v-alert :type="alertType">
                                  <v-row align="center">
                                   <v-col class="grow" cols="10">
                                        {{message}}
                                   </v-col>
                                   <v-col cols="2" class="shrink"  v-if="exported">
                                        
                                        <v-btn :to="{name:'admin.exports.list'}" color="dark">EXPORTS</v-btn>
                                   </v-col>
                                   </v-row>
                             </v-alert>
                        </v-card-text>
                   </v-card>
               </v-col>
               <v-col cols="12">
                    <simple-filters
                         :meta="meta" 
                         :filters.sync="filters" 
                         :loading="loading" 
                         @change="getReceipts"
                         :stores="stores"
                    ></simple-filters>
               </v-col>
               <v-col cols="12">
                    <filters 
                              :loading="loading" 
                               :filters.sync="filters"
                               :meta="meta"
                               :columns="aviableFilters"
                               table="receipts"
                               @change="getReceipts"
                    ></filters>
               </v-col>
               <v-col cols="12" >
                   <v-card :loading="loading">
                        <v-card-text>
                         <paginated-table 
                           :headers="headers" 
                           :table="receipts" 
                           id="receipt_uid" 
                           :meta="meta"
                           :loading="loading"
                           view
                           name="admin.receipts.single"  
                           @change="getReceipts"
                         ></paginated-table>
                        </v-card-text>
                   </v-card>
               </v-col>
          </v-row>
     </v-container>
</template>
<script>
import {mapGetters} from "vuex";
import ReceiptsFilters from '@/components/Admin/Receipts/ReceiptsFilters';
import SimpleReceiptsFilters from '@/components/Admin/Receipts/SimpleReceiptsFilters';
import DialogSimple from '@/components/Commons/DialogSimple.vue';
import UploaderCsv from '@/components/Admin/Receipts/UploaderCsv.vue';

export default {

     computed: {
     ...mapGetters({
          receipts: 'adminReceipts/receipts',
          meta: 'adminReceipts/meta',
          stores: 'adminStores/stores',
      }),
     },
     components:{
          'filters': ReceiptsFilters,
          'simple-filters': SimpleReceiptsFilters,
          'dialog-simple': DialogSimple,
          'uploader-csv': UploaderCsv,
     },
     data: () => ({
          loading: false,
          search: null,
          title: 'RECEIPT EXPORT TITLE',
          description: 'RECEIPT EXPORT DESCRIPTION',
          headers:[
              // {text:'attributes.country.label', value: 'country', sortable: false},
               {text:'attributes.amount.label', value: 'amount', sortable: true},
               {text:'attributes.fullname.label', value: 'user.fullname', sortable: false},
               {text:'attributes.store_name.label', value: 'store.name', sortable: false},
               {text:'attributes.track_code.label', value: 'receipt_uid', sortable: false, align: 'right', },
               {text:'attributes.issued_on.label', value: 'issued_on', sortable: false, align: 'right'},
          ],
          aviableFilters: [
               {text:"receipts.amount", value:'receipts.amount'},
               {text:"receipts.number", value:'receipts.number'},
               {text:"receipts.issued_on", value:'receipts.issued_on'},
               // {text:"receipts.store_uid", value:'receipts.store_uid'},
               {text:"users.uid", value:'receipts.user_uid'},
          ],
          errors: {},
          exported: null,
          message: null,
          exportFile: {
               title: 'RECEIPTS EXPORT',
               description: 'RECEIPTS EXPORT DESCRIPTION',
               resources_type: 'SIMPLE'
          },
          filters: {wheres : []},
          alertType: 'warning'
     }),
     beforeMount() {
          this.getReceipts()
          if(!this.stores.length){
               this.getStores()
          }else{
               this.addFilterStore(this.stores)
          }
     },
     methods:{
          addFilterStore(stores){

               var stores_values = []

                    stores.forEach(store => {
                         console.log('stores_value',store)
                         stores_values.push({text:store.name,value:store.store_uid})
                    });
                    
                    this.aviableFilters.push({
                         text:"receipts.store_uid", 
                         value:'receipts.store_uid',
                         type:'select',
                         values: stores_values
                    })


          },
          async getStores(){
               this.loading = true;
                this.$store.dispatch('adminStores/getStores')
                .then((response) => {
                     this.loading = false
                     console.log(response)
                    this.addFilterStore(this.stores)
                })
                
          },
         async getReceipts(){
              this.loading = true;
              this.$store.dispatch('adminReceipts/setMeta',this.meta)
              this.$store.dispatch('adminReceipts/getReceipts',this.filters)
              .then((response) => {
                    this.loading = false
                    this.message = null
                    this.errors = {}
                    console.log(response)
              })
              .catch((err) => {
                    console.error('ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               })
         },
          viewSingle(){
               if(this.search){
                    var newroute = {name:'admin.receipts.single',params:{id:this.search}};
                    this.$router.push(newroute);
               }else{
                    this.$refs["search"].focus();
               }
          },
          async exportReceipts(){
               this.loading = true;
               console.log('export on filters:', this.filters)
               
               this.message = "DON'T LEAVE THIS PAGE!";
               //this.alertType = "info";
             
               this.filters.title =  this.exportFile.title
               this.filters.description =  this.exportFile.description
               this.filters.resources_type =  this.exportFile.resources_type
               
               var self = this;
               this.$store.dispatch('adminReceipts/exportReceipts', this.filters)
               .then((response) => {
                    self.alertType = "success";
                    self.message = "EXPORT CREATE GO TO EXPORTS SECTION";
                    self.exported = response.data;
                    self.loading = false;

               });

               //  setTimeout(function () {
               //      self.loading = false;
               //      self.alertType = "success";
               //      self.message = "EXPORT CREATE GO TO EXPORTS SECTION";
               //      self.exported = true;
               //      console.log('loading',this.loading)
               // }, 2000);

              
         }
     }
}
</script>