<template>
     <v-row>
          <v-col cols="11" class="mx-auto my-4" v-if="isAdmin">
               <v-card :loading="loading">
                    <v-card-text>
                         <paginated-table 
                           :headers="headers" 
                           :table="stores" 
                           id="store_uid" 
                           :meta="meta"
                           :loading="loading"
                           view
                           name="admin.stores.single"  
                           @change="getUserStores"
                         ></paginated-table>
                    </v-card-text>
               </v-card>
          </v-col>
     </v-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
     name:"StoresList",
     props:{
          uid:{
               type: String,
               required: true,
          }
     },
     computed: {
          ...mapGetters({
                stores: 'adminUsersStores/stores',
                meta: 'adminUsersStores/meta',
                isAdmin: 'auth/isAdmin',
          }),
     },
     beforeMount(){
          
          this.getUserStores()
          
     },
     data: () => ({
          loading: true,
          headers:[
               // {text:'#', value: 'store_uid', sortable: false},
               {text:'attributes.store_name.label', value: 'name', sortable: false},
               {text:'attributes.action.label', value: 'pivot.action', sortable: false},
               {text:'attributes.created_at.label', value: 'pivot.created_at', sortable: false},
          ],
          errors: null,
          message: null,
     }),
     methods:{
          async getUserStores(){
               this.$store.dispatch('adminUsersStores/getUserStores',this.uid).then((response) => {
                    console.log('getUserStores success!',response)
                    this.loading = false;
               })
               .catch((err) => {
                    console.error('LOGIN ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               }) 
          },
          page(page){
               this.loading = true
               this.meta.current_page = page
               this.$store.commit('adminUsersStores/meta',this.meta)
               this.getUserStores()
          }
     },
}
</script>