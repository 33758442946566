<template>
     <v-container class="my-5" >
          <v-row>
               <v-col cols="12">
                   <v-card>
                        <v-card-title primary-title>
                              <h1>{{$t('admin.stores.title')}}</h1>
                        </v-card-title>
                        <v-card-text>
                             {{$t('admin.stores.description')}}
                        </v-card-text>
                        <v-card-actions>
                             <v-spacer></v-spacer>
                             <dialog-fullscreen :button="$t('commons.create')+' '+$t('commons.store')" :title="$t('commons.create')+' '+$t('commons.store')">
                                  <create-store></create-store>
                             </dialog-fullscreen>
                        </v-card-actions>
                   </v-card>
               </v-col>
               <v-col cols="12">
                    <simple-stores-filters
                        :meta="meta" 
                         :filters.sync="filters" 
                         :loading="loading" 
                         @change="getStores"
                    ></simple-stores-filters>
               </v-col>
               <v-col cols="12" >
                   <v-card :loading="loading">
                        <v-card-text>
                         <paginated-table 
                           :headers="headers" 
                           :table="stores" 
                           id="store_uid" 
                           :meta="meta"
                           :loading="loading"
                           view
                           name="admin.stores.single"  
                           @change="getStores"
                         ></paginated-table>
                        </v-card-text>
                   </v-card>
               </v-col>
          </v-row>
     </v-container>
</template>
<script>
import {mapGetters} from "vuex";
import DialogFullScreen from '@/components/Commons/DialogFullScreen';
import CreateStore from '@/components/Admin/Stores/CreateStore';
import SimpleStoresFilters from '@/components/Admin/Stores/SimpleStoresFilters.vue';

export default {
     computed: {
     ...mapGetters({
          stores: 'adminStores/stores',
          meta: 'adminStores/meta'
      }),
     },
     components:{
          'dialog-fullscreen': DialogFullScreen,
          'create-store': CreateStore,
          'simple-stores-filters': SimpleStoresFilters,
     },
     data: () => ({
          loading: false,
          headers:[
               {text:'attributes.country.label', value: 'country', sortable: false},
               {text:'attributes.store_code.label', value: 'store_code', sortable: false},
               {text:'attributes.store_name.label', value: 'name', sortable: false},
               {text:'attributes.city.label', value: 'city', sortable: false},
          ],
          errors: null,
          message: null,
          filters: {wheres : []},
     }),
     beforeMount() {
          this.getStores()
     },
     methods:{
         async getStores(){
              this.loading = true;
              this.$store.dispatch('adminStores/setMeta',this.meta)
              this.$store.dispatch('adminStores/getStores',this.filters)
              .then((response) => {
                    this.loading = false
                    this.message = null
                    this.errors = null
                    console.log(response)
              })
              .catch((err) => {
                    console.error('ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               })
         }
     }
}
</script>