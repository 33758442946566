<template>
     <v-container class="my-5" >
          <v-row>
               <v-col cols="12" v-if="singlexport">
                   <v-card :loading="loading">
                        
                        <v-card-title primary-title>
                            {{ [ singlexport.created_at, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ] | moment(" D MMMM YYYY, HH:mm") }}
                            <v-spacer></v-spacer>
                            <v-btn icon color="primary" @click="playOrPause(singlexport)" v-if="singlexport.status != 'saved'" class="me-4">
                              <v-icon v-if="singlexport.status == 'loading'">mdi-pause</v-icon>
                              <v-icon v-else-if="singlexport.status == 'error'">mdi-play</v-icon>
                            </v-btn>
                            {{ singlexport.status }}
                              <span v-if="(singlexport.status)=='saved'"><v-icon color="green accent-4">mdi-content-save</v-icon></span>
                              <span v-else-if="(singlexport.status)=='usable'"><v-icon color="green accent-4">mdi-check</v-icon></span>
                              <span v-else-if="(singlexport.status)=='added'"><v-icon color="info accent-4">mdi-plus</v-icon></span>
                              <span v-else-if="(singlexport.status)=='error'"><v-icon color="red accent-4">mdi-close-octagon</v-icon></span>
                              <span v-else-if="(singlexport.status)=='used'"><v-icon color="info accent-4">mdi-check-all</v-icon></span>
                              <span v-else class="mx-2">{{singlexport.percent}} % <v-icon color="dark accent-4 " >mdi-loading mdi-spin</v-icon></span>
                         </v-card-title>
                         <v-card-text v-if="singlexport.percent != 100">
                              <v-progress-linear striped height="20" v-model="singlexport.percent" color="primary">
                                   <strong>{{ Math.ceil(singlexport.percent) }}%</strong>
                              </v-progress-linear>
                         </v-card-text>
                         <v-divider></v-divider>
                         <v-card-text>
                              <h1>{{ singlexport.title }} </h1>
                              <p class="mb-0 mt-2">{{  singlexport.description }}</p>
                         </v-card-text>
                         <v-divider></v-divider>
                         <v-card-text>
                              <h4>{{ $t('attributes.data-filters.label') }}</h4>
                              <simple-table :table="singlexport['data-filters']"></simple-table>
                              <!-- <json-debug :objs="[{'EXPORT':singlexport}]"></json-debug> -->
                         </v-card-text>
                         <v-divider></v-divider>
                         <v-card-actions>
                              <v-row>
                                   <v-col>
                                        <v-btn :disabled="!singlexport.url" block color="primary" dark :href="singlexport.url" download target="_blank">
                                             <v-icon>mdi-download</v-icon>
                                             DOWNLOAD
                                        </v-btn>
                                   </v-col>
                                   <v-col>
                                        <v-btn block color="ERROR" dark @click="deleteExport(singlexport.id)">
                                             <v-icon>mdi-delete</v-icon>
                                             DELETE
                                        </v-btn>
                                   </v-col>
                              </v-row>
                         </v-card-actions>
                   </v-card>
               </v-col>
          </v-row>
     </v-container>
</template>


<script>
import {mapGetters} from "vuex";

export default {
     computed: {
     ...mapGetters({
          singlexport: 'adminExports/export',
          loading: 'loading/loading'
      }),
     },
     mounted(){
          this.getSingleExport(this.$route.params.id)
     },
     data: () => ({
     }),
     methods:{
          async getSingleExport(id){
               this.$store.dispatch('adminExports/getExport',id).then(() => {
                    this.checkLoading() 
               })
          },
          deleteExport(id){
               var sure = confirm("Are you sure you want to delete this export?")
               if(sure){
                    this.$store.dispatch('adminExports/deleteExport',id)
                    .then(()=>{
                         this.$router.push('/dashboard/admin/exports')
                    })
               }
          },
          playOrPause(item){
               console.log('playOrPause',item);
               item.status = (item.status==='loading') ? 'error' : (item.status==='error') ? 'loading' :'saved';
               this.$store.dispatch('adminExports/updateExport',item)
               .then(() => {
                    this.getSingleExport(item.id)
               })
         },
         checkLoading(){
          var result = (this.singlexport.status == 'loading')
          clearInterval(this.interval);
          if(result){
               console.log('result',result)
               var self = this;
               this.interval = setInterval(function() {
                    self.getSingleExport(this.singlexport.id)
               }, 30000);
               return true;
          }else{
               console.log('result FALSE',result)
               clearInterval(this.interval);
               return false;
          }
         }
     }
}
</script>