<template>
     <v-row>
          <v-col cols="12">
               <v-progress-linear
                    indeterminate
                    v-if="loading"
                    color="primary"
               ></v-progress-linear>
          </v-col>
          <v-col cols="6" >
               <h3>USER POINTS</h3>
               <p>ONLY ADMIN</p>
          </v-col>
          <v-col cols="6" class="text-center">
               <h1 class="my-3">{{ parseInt(newPoints)+points.total_points}}</h1>
          </v-col>
          <v-col cols="12" v-if="isAdmin">
               <v-text-field
               v-model="newPoints"
               type="number"
               label="POINTS"
               solo
               dense
               ></v-text-field>
               <v-btn :loading="loading" color="primary" @click="updatePoints" block>SAVE POINTS</v-btn>
          </v-col>

     </v-row>
</template>
<script>
import {mapGetters} from "vuex";

export default {
     computed: {
     ...mapGetters({
          isAdmin: 'auth/isAdmin',
      }),
     },
     name:"FormUserPoints",
     props:{
          uid:{
               type:String,
               required: true,
          },
          points:{
               type:Object,
               required: true,
          }
     },
     data: () => ({
          loading : false,
          newPoints: 0,
          errors: {},
     }),
     methods:{
          updatePoints(){
               var param = {}
               this.loading = true;
               param.points = this.newPoints
               param.uid = this.uid
               this.$store.dispatch('adminUsers/updatePoints', param)
               .then((response) => {
                    this.newPoints = 0
                    console.log('SUCCESS',response)
                         this.loading = false;
               })
               .catch((err) => {
                         console.error('SENT POINTS ERROR!',err)
                         this.errors = err.response.data.errors
                         this.loading = false;
                    })
          }
     }
}
</script>
