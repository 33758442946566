<template>
     <v-row>
          <v-col cols="11" class="mx-auto my-4" v-if="isAdmin">
               <v-card :loading="loading">
                    <v-card-text>
                         <paginated-table 
                           :headers="headers" 
                           :table="completedcoupons" 
                           id="coupon_uid" 
                           :meta="meta"
                           :loading="loading"
                           name="admin.coupons.single"  
                           @change="getUserCouponsCompleted"
                         ></paginated-table>
                    </v-card-text>
               </v-card>
          </v-col>
     </v-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
     name:"CompletedCouponsList",
     props:{
          uid:{
               type: String,
               required: true,
          }
     },
     computed: {
          ...mapGetters({
                completedcoupons: 'adminUsersCompletedCoupons/completedcoupons',
                meta: 'adminUsersCompletedCoupons/meta',
                isAdmin: 'auth/isAdmin',
          }),
     },
     beforeMount(){
          
          this.getUserCouponsCompleted()
          
     },
     data: () => ({
          loading: true,
          headers:[
               {text:'attributes.coupon_title.label', value: 'title', sortable: false},
               {text:'attributes.store_name.label', value: 'store.name', sortable: false},
               {text:'attributes.status.label', value: 'status', sortable: false},
               {text:'attributes.used_at.label', value: 'used_at', sortable: false},
          ],
          errors: null,
          message: null,
     }),
     methods:{
          async getUserCouponsCompleted(){
               this.$store.dispatch('adminUsersCompletedCoupons/getUserCouponsCompleted',this.uid).then((response) => {
                    console.log('completedcoupons success!',response)
                    this.loading = false;
               })
               .catch((err) => {
                    console.error('LOGIN ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               }) 
          },
          page(page){
               this.loading = true
               this.meta.current_page = page
               this.$store.commit('adminUsersCompletedCoupons/meta',this.meta)
               this.getUsercoupons()
          }
     },
}
</script>