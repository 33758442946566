<template>
     <v-container class="my-5" >
          <v-row>
               <v-col cols="12">
                   <v-card :loading="loading">
                        <v-card-title primary-title>
                            <h1>{{$t('admin.analytics.title')}} </h1>
                        </v-card-title>
                        <v-card-text>
                             {{$t('admin.analytics.description')}}
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text>
                             <v-row class="mt-2">
                                  <v-col cols="12" md="8">
                                       <datepicker 
                                        v-if="today"
                                             outlined
                                             label="Select a date"
                                             min="2021-05-01"
                                             :max="this.$moment().format('YYYY-MM-DD')"
                                             :date.sync="today"
                                        ></datepicker>
                                  </v-col>
                                  <v-col  cols="12" md="4">
                                        <v-btn block @click="rigenerateAnalytics" color="primary" class="mt-2">rigenerate for THIS DAY</v-btn>
                                   </v-col>
                              </v-row>
                        </v-card-text>
                   </v-card>
               </v-col>
               <template v-if="analytics" >
               <v-col cols="12" md="6" v-for="(analysis,index) in analytics.data" :key="analysis.table+today+index" >
                    <v-card :loading="loading">
                         <v-card-title primary-title>
                              <h3>{{ analysis.title }}</h3>
                              <v-spacer></v-spacer>
                              <v-avatar
                                   color="light"
                                   size="28"
                                   >
                                        <v-img :src="'/img/flags/'+analysis.country.toLowerCase()+'.png'"></v-img>
                              </v-avatar>
                         </v-card-title>
                         <v-card-text>
                              <p>{{ [ analysis.updated_at ] | moment("dddd, D MMMM YYYY HH:mm") }}</p>
                         </v-card-text>
                         <v-divider></v-divider>
                         <v-card-text>
                              <bar-chart :key="analysis.slug+'full'"  :table="analysis"></bar-chart>
                              <!-- <bar-chart :key="analysis.slug+'mini'" :labels="['daily','yesterday','weekly','last_week']" :table="analysis"></bar-chart>
                              <bar-chart :key="analysis.slug+'md'" :labels="['monthly','last_month','annual']" :table="analysis"></bar-chart>
                              <bar-chart :key="analysis.slug+'large'" :labels="['total']" :table="analysis"></bar-chart> -->
                         </v-card-text>
                         <v-card-actions>
                              <v-btn outlined block :to="{'name':'admin.analytics.single',params:{'id':analysis.slug}}" color="primary">
                                   {{$t('commons.view')}} {{$t('commons.chart')}}
                              </v-btn>
                         </v-card-actions>
                    </v-card>
               </v-col>
               </template>
          </v-row>
     </v-container>
</template>
<script>
import {mapGetters} from "vuex";
import datepicker from '@/components/Commons/FormValues/datepicker.vue';
import BarChart from '../../../../components/Admin/Analyse/barChart.vue';

export default {
     computed: {
     ...mapGetters({
          analytics: 'adminAnalytics/analytics',
      }),
     },
     components:{
          datepicker,
          BarChart
     },
     data: () => ({
          loading: true,
          errors: {},
          message: null,
          today: null,
     }),
     watch:{
          today(val){
               console.log(val)
               this.getAnalytics()
          }
     },
     beforeMount() {
          this.today = this.$moment().format('YYYY-MM-DD')         
          this.getAnalytics()
     },
     methods:{
         async getAnalytics(){
              this.loading = true;
              this.$store.dispatch('adminAnalytics/getAnalytics',{today:this.today})
              .then((response) => {
                    this.loading = false
                    this.message = null
                    this.errors = null
                    console.log(response)
              })
              .catch((err) => {
                    console.error('ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               })
         },
         async rigenerateAnalytics(){
              this.loading = true;
              //this.today = this.$moment().format('YYYY-MM-DD');
              var data = {
               date: this.today
              }
              this.$store.dispatch('adminAnalytics/rigenerateAnalytics',data)
              .then((response) => {
                    this.loading = false
                    this.message = null
                    this.errors = null
                    console.log(response)
              })
              .catch((err) => {
                    console.error('ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               })
         }
     }
}
</script>