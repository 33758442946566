<template>
     <div>
          <v-container class="my-5" >
          <v-row>
               <v-col cols="12">
                   <v-card :loading="loading">
                        <v-card-title primary-title v-if="store">
                             <div>
                                  <h3>{{store.name}}</h3>
                             </div>

                             <v-spacer></v-spacer>
                              {{store.store_code}}
                        </v-card-title>
                   </v-card>
               </v-col>
               <v-col cols="12" md="6">
                   <v-card :loading="loading">
                        <v-card-title primary-title>
                            {{ $t('commons.store') }}
                        </v-card-title>
                        <v-card-text v-if="store">
                             <simple-table :table="{
                                  store_name:store.name,
                                  state: store.state,
                                  timezone: store.timezone,
                                  region: store.region,
                                  province:store.province,
                                  city:store.city,
                                  address:store.address_street+', '+store.address_number,
                                  phone:store.phone_number,
                                  email:store.email,
                             }" ></simple-table> 
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                             <dialog-simple width="800px" :button="$t('commons.edit')+' '+$t('commons.store')" :title="$t('commons.store')">
                                  <slot>
                                       <update-store :store="store"></update-store>
                                  </slot>
                             </dialog-simple>
                        </v-card-actions>
                   </v-card>
               </v-col>
               <v-col cols="12" md="6">
                   <v-card :loading="loading">
                        <v-card-title primary-title>
                            {{ $t('commons.managers') }}
                        </v-card-title>
                        <v-card-text v-if="store">
                             <v-list-item
                                  v-for="manager in store.managers"
                                  :key="manager.uid"
                                  :to="{'name':'admin.users.single', 'params':{'id':manager.uid}}"
                             >
                                   <v-list-item-icon>
                                   <v-icon v-text="$t('roles.manager.icon')"></v-icon>
                                   </v-list-item-icon>
                                   <v-list-item-content>
                                        <v-list-item-title>{{manager.fullname}}</v-list-item-title>
                                         <v-list-item-subtitle>{{manager.email}}</v-list-item-subtitle>
                                   </v-list-item-content>
                             </v-list-item>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions v-if="store">
                             <dialog-simple outlined block width="800px" :button="'Add New manager'" :title="'Add New manager'">
                                  <slot>
                                       <attach-manager :store_uid="store.store_uid"></attach-manager>
                                  </slot>
                             </dialog-simple>
                        </v-card-actions>
                        <v-card-actions v-if="store">
                             <dialog-simple outlined block width="800px" :button="'Remove manager'" :title="'Remove manager'">
                                  <slot>
                                       <detach-manager :store_uid="store.store_uid"></detach-manager>
                                  </slot>
                             </dialog-simple>
                        </v-card-actions>
                   </v-card>
               </v-col>
          </v-row>
          </v-container>
     </div>
</template>


<script>
import {mapGetters} from "vuex";
import UpdateStore from '@/components/Admin/Stores/UpdateStore.vue';
import AttachManager from '@/components/Admin/Stores/AttachManager.vue';
import DetachManager from '@/components/Admin/Stores/DetachManager.vue';
import DialogSimple from '@/components/Commons/DialogSimple.vue';

export default {
     components: { 
        'dialog-simple' :  DialogSimple,
        'update-store' : UpdateStore,
        'attach-manager': AttachManager,
        'detach-manager': DetachManager,
      },
     computed: {
          ...mapGetters({
                store: 'adminStores/store',
          }),
     },
     beforeMount() {
          if(!this.store){
               this.getStore(this.$route.params.id)
          }else if(this.store.store_uid != this.$route.params.id){
               this.getStore(this.$route.params.id)
          }else{
               this.loading = false;
          }
     },
     data: () => ({
          loading: true,
          timezones: ['Europe/Madrid','Europe/Rome','Europe/Lisbon']
     }),
     methods:{
          getStore(uid){
              this.$store.dispatch('adminStores/getStore',uid)
              this.loading = false
          }
     }
}
</script>