<template>
     <v-expansion-panels :disabled="loading">
          <v-expansion-panel :disabled="loading">
               <v-expansion-panel-header>
               <h2>{{$t('commons.filters')}}</h2>
               </v-expansion-panel-header>
               <v-expansion-panel-content>
               <v-row>
                    <v-col>
                         <!-- <v-btn @click="addWheres">addWheres</v-btn> -->
                         <v-select
                              :disabled="loading"
                              @input="addWheres"
                              :items="columns"
                              :item-text="textColumnItem"
                              :item-value="itemValue"
                              outlined
                              label="Aviable Filters"
                         ></v-select>
                    </v-col>
                    <template v-if="filters">
                    <v-col cols="12"  v-for="(where,i) in filters.wheres" :key="i">
                       <v-card :disabled="loading">
                            <v-card-title primary-title>
                                 <h3>{{ $t('filters.'+ where.column+'.title') }}</h3>
                                   
                                 <v-spacer></v-spacer>
                                 <v-btn @click="removeWhere(i)" icon><v-icon>mdi-close</v-icon></v-btn>
                            </v-card-title>
                            <v-card-text>
                                 <v-row >
                                      <v-col cols="12">
                                           {{ $t('filters.'+ where.column+'.description') }}
                                      </v-col>
                                      <v-col cols="2" >
                                           <v-select
                                              v-if="i != 0"
                                             v-model="where.type"
                                             :items="['and','or']"
                                             ></v-select>
                                      </v-col>
                                      <v-col cols="2" class="text-center">
                                           <v-select
                                             v-model="where.is"
                                             :items="['=','like','>','>=','<','<=']"
                                             @input="isLike(where)"
                                             ></v-select>
                                      </v-col>
                                      <v-col cols="8" class="text-center">
                                           <template v-if="where.config.type == 'select'">
                                                <v-select
                                                     :items="where.config.values"
                                                     v-model="where.value"
                                                ></v-select>
                                           </template>
                                           <template v-else>
                                           <v-text-field v-model="where.value">
                                           </v-text-field>
                                           </template>
                                      </v-col>
                                 </v-row>
                            </v-card-text>
                       </v-card>
                    </v-col>
                    </template>
                    <v-col cols="12">
                         <v-btn :disabled="loading" @click="page" color="primary"> <v-icon>mdi-filter</v-icon> UseFilters</v-btn>
                         <v-btn :disabled="loading" @click="removeWhere(-1)" outlined color="primary" class="float-right"><v-icon>mdi-close</v-icon> deleteFilters</v-btn>
                    </v-col>
               </v-row>
               </v-expansion-panel-content>
          </v-expansion-panel>
     </v-expansion-panels>
</template>
<script>
export default {
     name: 'filters',
     props:{
          filters:{
               type: Object,
               required: false,
          },
          columns:{
               type: Array,
               required: false
          },
          meta:{
               type: Object,
               required: true
          },
          loading:{
               type: Boolean,
               required: false
          },
          table:{
               type: String,
               required: true,
          }
     },
     methods:{
          textColumnItem(item){return this.$t('filters.'+item.text+'.title')},
          itemValue(item){return item},
          isLike(where){
               if(where.is == "like"){
                    where.value ="%%"
               }
          },
          page(){
               this.meta.current_page = 1
               this.$emit('update:meta',this.meta)
               this.$emit('change')
          },
          addWheres(item){
               if(!this.filters){
                 this.filters = {wheres : []}
               }
               console.log('item',item)
               this.filters.wheres.push({'type':'and','column':item.value,'is':null,'value':null,config:{type:item.type,values:item.values}})

               var hasTable = item.value.split('.');
               if(hasTable.length >= 2){
                    if(hasTable[0] != this.table){
                         this.addTable(hasTable[0]);
                    }
               }

               // this.$emit('update:filters',this.filters)
          },
          removeWhere(i){
               if(i >= 0){
                    this.filters.wheres.splice(i, 1);
               }else{
                    this.filters.wheres = []
                    this.filters.tables = []
                    this.$emit('change')
               }
               //  this.$emit('update:filters',this.filters)               
          },
          addTable(table){
               if(!this.filters.tables){
                 this.filters.tables = [];
               }

               var FOUND = this.filters.tables.find(function(t) {
                    if(t.table == table)
                         return true;
               });

               if(!FOUND){
                    if(table == 'store_user'){
                         this.filters.tables.push({'table':table,'on':'user_id','with':'id'}) 
                    }else{
                         this.filters.tables.push({'table':table,'on':'user_uid','with':'uid'})             
                    }
               }

          }
     }
}
</script>