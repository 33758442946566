<template>
     <v-autocomplete
          outlined
          :loading="loading"
          :items="stores"
          v-model="store"
          :search-input.sync="search"
          name="store_uid"
          clearable
          @keyup="getStores"
          @change="selected"
          :label="$t('attributes.store_name.label')"
          dense
           return-object
          item-text="name"
          prepend-inner-icon="mdi-store"
          >
     </v-autocomplete>
</template>
<script>
import {mapGetters} from "vuex";

export default {
      computed: {
          ...mapGetters({
               stores: 'adminStores/stores',
          }),
     },
     props:{
          return: {
               required: false,
          }
     },
     data: () => ({
          loading: false,
          store: null,
          search: null,
          filters: {wheres : []},
     }),
     methods:{
          selected(){
                   console.log('store SELECTED', this.store);
                   //this.$emit('update:store_uid', this.store_uid)

                    var target = {
                              value: this.store,
                              name: 'store'
                         }

                    if(this.store){
                         if(this.return){
                              target.value = this.store[this.return];
                              target.name = this.return;
                         }
                         console.log('target!!!!!!!!!!!',target)
                         this.$emit('change', {target} );
                    }else{
                         if(this.return){
                              target.value = '';
                              target.name = this.return;
                         }
                        this.$emit('change', {target} );
                    }

          },
          async getStores(){
               console.log('VALUE!!!!', this.search);
               this.loading = true;
               
               this.filters =  {wheres : []};
               this.filters.wheres.push( {'type':'and','column':'name','is':'like','value':'%'+this.search+'%'} );

              this.$store.dispatch('adminStores/getStores',this.filters)
              .then((response) => {
                    this.loading = false
                    this.message = null
                    this.errors = null
                    console.log(response)
              })
              .catch((err) => {
                    console.error('ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               })
         }
     }
}
</script>