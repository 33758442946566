<template>
     <v-card :loading="loading">
          <v-card-text>
              <v-row>
                        <v-col class="mt-5" cols="4"  >
                              <v-text-field
                                   outlined
                                   v-model="amount"
                                   dense
                                   class="text-uppercase"
                                   @keyup.enter="addFilters"
                                   name="amount"
                                   :label="$t('attributes.amount.label')"
                                   prepend-inner-icon="mdi-currency-eur"
                              ></v-text-field>
                         </v-col>
                         <v-col class="mt-5" cols="4"  >
                              <v-text-field
                                   outlined
                                   v-model="user_uid"
                                   dense
                                   class="text-uppercase"
                                   @keyup.enter="addFilters"
                                   name="user_uid"
                                   :label="$t('attributes.uid.label')"
                                   prepend-inner-icon="mdi-ticket"
                              ></v-text-field>
                         </v-col>
                         <v-col class="mt-5" cols="4"  >
                              <v-text-field
                                   outlined
                                   v-model="receipt_uid"
                                   dense
                                   class="text-uppercase"
                                   @keyup.enter="addFilters"
                                   name="receipt_uid"
                                   :label="$t('attributes.track_code.label')"
                                   prepend-inner-icon="mdi-network-pos"
                              ></v-text-field>
                         </v-col>
                         <v-col class="" cols="4"  >
                              <v-text-field
                                   outlined
                                   v-model="number"
                                   dense
                                   class="text-uppercase"
                                   @keyup.enter="addFilters"
                                   name="number"
                                   :label="$t('attributes.number.label')"
                                   prepend-inner-icon="mdi-cash-register"
                              ></v-text-field>
                         </v-col>
                         <v-col class="" cols="4"  >
                              <v-text-field
                                   outlined
                                   type="date"
                                   v-model="created_at"
                                   dense
                                   class="text-uppercase"
                                   @keyup.enter="addFilters"
                                   name="created_at"
                                   :label="$t('attributes.created_at.label')"
                                   prepend-inner-icon="mdi-calendar"
                              ></v-text-field>
                         </v-col>
                         <v-col class="" cols="4"  >
                              <storepicker return="store_uid" @change="myEv"></storepicker>
                         </v-col>
                         
              </v-row>
          </v-card-text>
     </v-card>
</template>
<script>
import storepicker from "@/components/Commons/FormValues/storepicker"
export default {
     name: 'filters',
     components: {
          storepicker
     },
     props:{
          filters:{
               type: Object,
               required: false,
          },
          meta:{
               type: Object,
               required: true
          },
          loading:{
               type: Boolean,
               required: false
          },
          stores:{
               type: Array,
               required: false
          },
     },
     data: () => ({
          user_uid: null,
          receipt_uid: null,
          amount: null,
          number: null,
          created_at: null,
          store_uid: null,
     }),
     methods:{
          get(){
               this.meta.current_page = 1
               this.$emit('update:meta',this.meta)
               this.$emit('change')
          },
          myEv(event){
               console.log("myEV",event)
               var newEv = event;
               this.store_uid = event.target.value
               this.addFilters(newEv)
          },
          addFilters(event){
               console.log('event addFilters',event)

               var value = event.target.value;
               var name = event.target.name;
               
               // if(name == 'store_uid'){
               //      value = this.store_uid
               // }

               var type = "and";

               console.log({'name':name,'value':value,'type':type})
              
              
                    var newhere = {'type':type,'column':name,'is':'like','value':'%'+value+'%',config:"text"}
                    this.filters.wheres = [];
                    this.filters.wheres.push(newhere)
                    this.get();
               
          },
          removeFilters(){
               this.filters.wheres = [];
               this.get();
          }
     }
}
</script>