<template>
     <v-container class="my-5" >
          <v-row>
               <v-col cols="12">
                   <v-card>
                        <v-card-title primary-title>
                              <h1>{{$t('admin.coupons.title')}}</h1>
                        </v-card-title>
                        <v-card-text>
                             {{$t('admin.coupons.description')}}
                        </v-card-text>
                        <v-card-actions>
                             <v-spacer></v-spacer>
                             <dialog-fullscreen :button="$t('commons.create')+' '+$t('commons.coupon')" :title="$t('commons.create')+' '+$t('commons.coupon')">
                                  <create-coupon></create-coupon>
                             </dialog-fullscreen>
                        </v-card-actions>
                   </v-card>
               </v-col>
               <v-col cols="12" >
                   <v-card :loading="loading">
                        <v-card-text>
                         <paginated-table 
                           :headers="headers" 
                           :table="coupons" 
                           id="coupon_uid" 
                           :meta="meta"
                           :loading="loading"
                           view
                           name="admin.coupons.single"  
                           @change="getCoupons"
                         ></paginated-table>
                        </v-card-text>
                   </v-card>
               </v-col>
               <v-col v-if="coupons">
                    
               </v-col>
          </v-row>
     </v-container>
</template>
<script>
import {mapGetters} from "vuex";
import DialogFullScreen from '@/components/Commons/DialogFullScreen';
import CreateCoupon from '@/components/Admin/Coupons/CreateCoupon';

export default {

     computed: {
     ...mapGetters({
          coupons: 'adminCoupons/coupons',
          meta: 'adminCoupons/meta'
      }),
     },
     components:{
            'dialog-fullscreen': DialogFullScreen,
            'create-coupon': CreateCoupon,
     },
     data: () => ({
          loading: false,
          headers:[
               {text:'attributes.country.label', value: 'country', sortable: false, align:"center"},
               {text:'attributes.active.label', value: 'active', sortable: false, align: "center"},
               {text:'attributes.coupon_code.label', value: 'coupon_code', sortable: false,align:"left"},
               {text:'attributes.title.label', value: 'title', sortable: false,align:"left"},
               {text:'attributes.discount_amount.label', value: 'discount_amount', sortable: false, align:"center"},
               {text:'attributes.valid_levels.label', value: 'valid_levels', sortable: false, align:"center"},
               {text:'attributes.points.label', value: 'points', sortable: false, align:"center"},
          ],
          errors: null,
          message: null,
          filters: null,
     }),
     beforeMount() {
          this.getCoupons()
     },
     methods:{
         async getCoupons(){
              this.loading = true;
              this.$store.dispatch('adminCoupons/setMeta',this.meta)
              this.$store.dispatch('adminCoupons/getCoupons',this.filters)
              .then((response) => {
                    this.loading = false
                    this.message = null
                    this.errors = null
                    console.log(response)
              })
              .catch((err) => {
                    console.error('ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               })
         }
     }
}
</script>