<template>
     <v-container class="my-5" >
          <v-row>
               <v-col cols="12">
                   <v-card>
                        <v-card-title primary-title>
                             <div>
                                  <h1 class="mb-2">{{$t('admin.dashboard.title')}}</h1>
                                  <h3>{{$t('admin.dashboard.description')}}</h3>
                                  <p class="mb-0" v-if="admin.profile.fullname">{{admin.profile.fullname}}</p>
                                  <p class="mb-0" v-else>{{admin.email}}</p>
                             </div>
                        </v-card-title>
                   </v-card>
               </v-col>
               <v-col cols="12" md="4" v-for="model in models" :key="model">
                   <v-card>
                        <v-card-title primary-title>
                             <v-avatar
                                             size="50"
                                             color="white"
                                             icon
                                             class="mx-3"
                                        >
                                             <v-icon large color="primary">{{$t('admin.'+model+'.icon')}}</v-icon>
                                   </v-avatar>
                             <div>
                                   <h2 class="mb-2">
                                  {{$t('admin.'+model+'.title')}}</h2>
                                  
                             </div>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                             <h3>{{$t('admin.'+model+'.description')}}</h3>
                        </v-card-text>
                        <v-card-actions class="text-right">
                             <v-spacer></v-spacer>
                           <v-btn :to="{name: 'admin.'+model+'.index'}" color="primary" >{{$t('admin.'+model+'.title')}}</v-btn>
                        </v-card-actions>
                   </v-card>
               </v-col>
          </v-row>
     </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
     computed: {
     ...mapGetters({
          admin: 'user/user'
      }),
     },
     methods:{
          getUser(){
                    this.$store.dispatch('user/getUser')
          }
     },
     data: () =>({
          models:['users','stores','coupons','receipts','analytics','exports']
     }),
     afterCreate(){
          if(!this.admin){
               this.$store.dispatch('user/getUser')
          }
     }
}
</script>