<template>
     <v-row>
          <v-col cols="11" class="mx-auto my-4">
               <v-card :loading="loading">
                    <v-card-text>
                   <paginated-table 
                           :headers="headers" 
                           :table="coupons" 
                           id="coupon_uid" 
                           :meta="meta"
                           :loading="loading"
                           name="admin.coupons.single"  
                           @change="getUserCoupons"
                  ></paginated-table>
                    </v-card-text>
               </v-card>
               <!-- <json-debug :objs="[{coupons}]" ></json-debug> -->
          </v-col>
          <v-col cols="11" class="text-end">
               <v-btn @click="checkUserCoupons" color="primary" >
                    <v-icon>mdi-reload</v-icon> checkUserCoupons
               </v-btn>
          </v-col>
     </v-row>
</template>
<script>
import {mapGetters} from "vuex";
export default {
     name: "CouponsAdminList",
     props:{
          user:{
               type: Object,
               required: true,
          }
     },
     computed: {
          ...mapGetters({
                coupons: 'adminUsersCoupons/coupons',
                loading: 'loading/loading',
                meta: 'adminUsersCoupons/meta',
          }),
     },
     data: () => ({
          headers:[
               {text:'attributes.status.label', value: 'status', sortable: false},
               {text:'attributes.coupon_title.label', value: 'title', sortable: false},
               {text:'attributes.expire_at.label', value: 'expire_at', sortable: false},
               {text:'attributes.add_at.label', value: 'created_at', sortable: false},
               {text:'attributes.updated_at.label', value: 'updated_at', sortable: false},
          ],
     }),
     beforeMount(){
          this.getUserCoupons()
     },
     methods:{
          async getUserCoupons(){
                this.$store.dispatch('adminUsersCoupons/getUserCoupons',this.user.uid)
          },
           async checkUserCoupons(){
                this.$store.dispatch('adminUsersCoupons/checkUserCoupons',this.user.uid)
          }
     }
}
</script>
