<template>
     <v-row>
          <v-col cols="12" v-if="loading">
               <v-progress-linear
                    indeterminate
                    color="primary"
               ></v-progress-linear>
          </v-col>
          <v-col v-if="Object.keys(errors).length">
               <v-alert
                    border="top"
                    colored-border
                    type="error"
                    elevation="2"
               >
                    <ul>
                    <li v-for="(err,field) in errors" :key="field">
                         <b>{{$t('attributes.'+field+'.label')}}</b>: <span v-for="(e,i) in err" :key="i" >{{e}}</span>
                    </li>  
                    </ul>
               </v-alert>
          </v-col>
          <v-col cols="12">
               <!-- <v-select
                    :loading="loading"
                    v-model="store.country"
                    v-if="countries"
                    :item-text="textCountryItem"
                    :items="countries"
                    :label="$t('attributes.country.label')"
                    item-value="country"
                    @change="changeCountry"
                    return-object
                    :error-messages="errors.country"
               ></v-select> -->
               <v-text-field
                    v-model="store.store_code"
                    :label="$t('attributes.store_code.label')"
                    :placeholder="$t('attributes.store_code.placeholder')"
                    :hint="$t('attributes.store_code.hint')"
                    :error-messages="errors.store_code"
                    
               ></v-text-field>
               <v-text-field
                    v-model="store.name"
                    :label="$t('attributes.store_name.label')"
                    :placeholder="$t('attributes.store_name.placeholder')"
                    :hint="$t('attributes.store_name.hint')"
                    :error-messages="errors.name"
                    
               ></v-text-field>
               <v-text-field
                    v-model="store.email"
                    :label="$t('attributes.email.label')"
                    :placeholder="$t('attributes.email.placeholder')"
                    :hint="$t('attributes.email.hint')"
                    :error-messages="errors.email"
                    
               ></v-text-field>
               <v-text-field
                    v-model="store.phone_number"
                    :label="$t('attributes.phone_number.label')"
                    :placeholder="$t('attributes.phone_number.placeholder')"
                    :hint="$t('attributes.phone_number.hint')"
                    :error-messages="errors.phone_number"
                    
               ></v-text-field>
               <v-text-field
                    v-model="store.city"
                    :label="$t('attributes.city.label')"
                    :placeholder="$t('attributes.city.placeholder')"
                    :hint="$t('attributes.city.hint')"
                    :error-messages="errors.city"
                    
               ></v-text-field>
               <v-text-field
                    v-model="store.address_street"
                    :label="$t('attributes.address_street.label')"
                    :placeholder="$t('attributes.address_street.placeholder')"
                    :hint="$t('attributes.address_street.hint')"
                    :error-messages="errors.address_street"
                    
               ></v-text-field>
          </v-col>
          <v-col cols="12">
               <v-btn :loading="loading" block @click="createStore" color="primary"><v-icon>mdi-save</v-icon> SAVE</v-btn>
               <json-debug :objs="[{store}]"></json-debug>
          </v-col>
     </v-row>
</template>

<script>
// import datepicker from '@/components/Commons/FormValues/datepicker.vue';

export default {
     name: "CreateStore",
     components:{
          // datepicker
     },
     data: () => ({
          loading: null,
          store:{},
          errors:{},
          message: null,
          countries: []
     }),
     mounted(){
          //this.getCountries();
          this.store.country = localStorage.getItem('country')
          this.store.timezone = localStorage.getItem('timezone')
     },
     methods:{
          getCountries(){
               this.loading = true
               this.$store.dispatch('general/getCountries',this.reg).then((response) => {
                    this.loading = false;
                    console.log('countries',response.data)
                    this.countries = response.data;
                    
               })
          },
          textCountryItem: item => item.country,
          changeCountry(item){
               console.log('country',item)
               this.store.country = item.country
               this.store.timezone = item.timezones[0]
          },
          async createStore(){
               this.loading = true
               console.log('CREATE store',this.store)

               this.$store.dispatch('adminStores/createStore',this.store)
                    .then((response) => {
                         console.log('CREATE success!',response)
                         this.message = response.data.message
                         var store_uid = response.data.store_uid
                         this.loading = false;
                         this.$router.push({name:'admin.stores.single',params:{'id':store_uid}})
                    })
                    .catch((err) => {
                         console.error('CREATE ERROR!',err)
                         this.message = err.response.data.message
                         this.errors = err.response.data.errors
                         this.loading = false;
                    }) 

          }
     }
}
</script>
