<template>
    <v-row>
         <v-col cols="12">
              <h4>DELETING THIS RECEIPTS?</h4>
         </v-col>
         <v-col cols="12">
              <v-btn @click="deleteReceipt" color="error">CONFIRM DELETE</v-btn>
         </v-col>
    </v-row>
</template>
<script>
export default {
     name: "DeleteReceipt",
     props:{
          receipt:{
               type:Object,
               required: true,
          }
     },
     methods:{
          deleteReceipt(){
               this.$store.dispatch('adminReceipts/deleteReceipt',this.receipt.receipt_uid)
              .then((response) => {
                    console.log('deleteReceipt success!',response)
                     this.$router.push({name:"admin.receipts.list"})
               })
               .catch((err) => {
                    this.loading = false
                    this.receipt = null
                    this.message = err.response.data.message
               })
          }
     }
}
</script>