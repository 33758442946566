<template>
     <v-row>
          <v-col cols="12">
               <h3>UPDATE STORE DATA</h3>
          </v-col>
          <v-col cols="12">
               <form ref="form">
                    <v-row>
                         <v-col cols="12">
                              <v-text-field 
                                   type="text"
                                   v-model="store.name"
                                   :label="$t('attributes.store_name.label')"
                                   name="name"
                                   required
                                   :hint="$t('attributes.store_name.hint')"
                                   :error-messages="errors.name"
                                   @focus="errors.name = []"
                              >
                              </v-text-field>
                         </v-col>
                         <v-col cols="12">
                              <v-text-field 
                                   type="text"
                                   v-model="store.email"
                                   :label="$t('attributes.email.label')"
                                   name="email"
                                   required
                                   :hint="$t('attributes.email.hint')"
                                   :error-messages="errors.email"
                                   @focus="errors.email = []"
                              >
                              </v-text-field>
                         </v-col>
                         <v-col cols="12">
                              <v-text-field 
                                   type="text"
                                   v-model="store.phone_number"
                                   :label="$t('attributes.phone.label')"
                                   name="phone"
                                   required
                                   :hint="$t('attributes.phone.hint')"
                                   :error-messages="errors.phone"
                                   @focus="errors.phone = []"
                              >
                              </v-text-field>
                         </v-col>
                         <v-col cols="6">
                              <v-text-field 
                                   type="text"
                                   v-model="store.state"
                                   :label="$t('attributes.state.label')"
                                   name="state"
                                   required
                                   :hint="$t('attributes.state.hint')"
                                   :error-messages="errors.state"
                                   @focus="errors.state = []"
                              >
                              </v-text-field>
                         </v-col>
                         <v-col cols="6">
                              <v-text-field 
                                   type="text"
                                   v-model="store.region"
                                   :label="$t('attributes.region.label')"
                                   name="region"
                                   required
                                   :hint="$t('attributes.region.hint')"
                                   :error-messages="errors.region"
                                   @focus="errors.region = []"
                              >
                              </v-text-field>
                         </v-col>
                         <!-- <v-col cols="12">
                              <v-select
                                   :items="timezones"
                                   type="text"
                                   v-model="store.timezone"
                                   :label="$t('attributes.timezone.label')"
                                   name="timezone"
                                   required
                                   :hint="$t('attributes.timezone.hint')"
                                   :error-messages="errors.timezone"
                                   @focus="errors.timezone = []"
                              >
                              </v-select>
                         </v-col> -->
                         
                         <v-col cols="4">
                              <v-text-field 
                                   type="text"
                                   v-model="store.province"
                                   :label="$t('attributes.province.label')"
                                   name="province"
                                   required
                                   :hint="$t('attributes.province.hint')"
                                   :error-messages="errors.province"
                                   @focus="errors.province = []"
                              >
                              </v-text-field>
                         </v-col>
                         <v-col cols="4">
                              <v-text-field 
                                   type="text"
                                   v-model="store.city"
                                   :label="$t('attributes.city.label')"
                                   name="city"
                                   required
                                   :hint="$t('attributes.city.hint')"
                                   :error-messages="errors.city"
                                   @focus="errors.city = []"
                              >
                              </v-text-field>
                         </v-col>
                         <v-col cols="4">
                              <v-text-field 
                                   type="text"
                                   v-model="store.zip_code"
                                   :label="$t('attributes.zip_code.label')"
                                   name="zip_code"
                                   required
                                   :hint="$t('attributes.zip_code.hint')"
                                   :error-messages="errors.zip_code"
                                   @focus="errors.zip_code = []"
                              >
                              </v-text-field>
                         </v-col>
                         <v-col cols="9">
                              <v-text-field 
                                   type="text"
                                   v-model="store.address_street"
                                   :label="$t('attributes.address_street.label')"
                                   name="address_street"
                                   required
                                   :hint="$t('attributes.address_street.hint')"
                                   :error-messages="errors.address_street"
                                   @focus="errors.address_street = []"
                              >
                              </v-text-field>
                         </v-col>
                         <v-col cols="3">
                              <v-text-field 
                                   type="text"
                                   v-model="store.address_number"
                                   :label="$t('attributes.address_number.label')"
                                   name="address_number"
                                   required
                                   :hint="$t('attributes.address_number.hint')"
                                   :error-messages="errors.address_number"
                                   @focus="errors.address_number = []"
                              >
                              </v-text-field>
                         </v-col>
                         <v-col>
                              <v-btn :loading="loading" @click="save" color="success">SAVE</v-btn>
                         </v-col>
                    </v-row>
               </form>
          </v-col>
     </v-row>
</template>
<script>
export default {
     name:"UpdateStore",
     props:{
          store:{
               type:Object,
               required: true,
          }
     },
     data: () => ({
          errors: {},
          loading: false,
          timezones: ['Europe/Madrid','Europe/Rome','Europe/Lisbon']
     }),
     methods:{
          async save(){
               this.loading = true
               var params = {
                    'country': this.store.country,
                    'store_code': this.store.store_code,
                    'store_uid': this.store.store_uid,
                    'name': this.store.name,
                    'timezone': this.store.timezone,
                    'email': this.store.email,
                    'phone_number': this.store.phone_number,
                    'city': this.store.city,
                    'province': this.store.province,
                    'zip_code': this.store.zip_code,
                    'address_street': this.store.address_street,
                    'address_number': this.store.address_number,
               };
               console.log(params)

               this.$store.dispatch('adminStores/updateStore',params)
               .then((response) => {
                    this.message = response.data.message
                    console.log('SAVE success!',response)
                    this.loading = false;
                    location.reload();
               })
               .catch((err) => {
                    console.error('SAVE ERROR!',err)
                    this.errors = err.response.data.errors
                    this.loading = false;
               }) 
          }
     }
}
</script>