<template>
     <v-row>
          <v-col cols="12" v-if="loading">
               <v-progress-linear
                    indeterminate
                    color="primary"
               ></v-progress-linear>
          </v-col>
          <v-col v-if="Object.keys(errors).length">
               <v-alert
                    border="top"
                    colored-border
                    type="error"
                    elevation="2"
               >
                    <ul>
                    <li v-for="(err,field) in errors" :key="field">
                         <b>{{field}}</b>: <span v-for="(e,i) in err" :key="i" >{{e}}</span>
                    </li>  
                    </ul>
               </v-alert>
          </v-col>
          <v-col cols="12">
               <v-select
                    :loading="loading"
                    v-model="coupon.country"
                    v-if="countries"
                    :item-text="textCountryItem"
                    :items="countries"
                    :label="$t('attributes.country.label')"
                    item-value="country"
                    @change="changeCountry"
                    return-object
                    :error-messages="errors.country"
               ></v-select>
          </v-col>
          <v-col cols="12">
               <v-text-field
                    v-model="coupon.coupon_code"
                    :label="$t('attributes.coupon_code.label')"
                    :placeholder="$t('attributes.coupon_code.placeholder')"
                    :hint="$t('attributes.coupon_code.hint')"
                    :error-messages="errors.coupon_code"
                    
               ></v-text-field>
               <v-text-field
                    v-model="coupon.title"
                    :label="$t('attributes.title.label')"
                    :placeholder="$t('attributes.title.placeholder')"
                    :hint="$t('attributes.title.hint')"
                    :error-messages="errors.title"
                    
               ></v-text-field>
               <v-text-field
                    v-model="coupon.description"
                    :label="$t('attributes.description.label')"
                    :placeholder="$t('attributes.description.placeholder')"
                    :hint="$t('attributes.description.hint')"
                    :error-messages="errors.description"
                    
               ></v-text-field>
          </v-col>
          <v-col cols="6">
              <datepicker 
                    :label="$t('attributes.start.label')"
                    :placeholder="$t('attributes.start.placeholder')"
                    :date.sync="coupon.start"
                    :max="coupon.end"
                    :errors="errors.start"
               ></datepicker>
          </v-col>
          <v-col cols="6">
              <datepicker 
                    :label="$t('attributes.end.label')"
                    :placeholder="$t('attributes.end.placeholder')"
                    :date.sync="coupon.end"
                    :min="coupon.start"
                    :errors="errors.end"
               ></datepicker>
          </v-col>
          <v-col cols="4">
              <v-checkbox
                    v-model="coupon.replicable"
                    :label="$t('attributes.replicable.label')"
                    :hint="$t('attributes.replicable.hint')"
                     :error-messages="errors.replicable"
               ></v-checkbox>
          </v-col>
          <v-col cols="4">
              <v-checkbox
                    v-model="coupon.autoadd"
                    :label="$t('attributes.autoadd.label')"
                    :hint="$t('attributes.autoadd.hint')"
                     :error-messages="errors.autoadd"
               ></v-checkbox>
          </v-col>
          <v-col cols="4">
                <v-text-field
                    type="number"
                    prepend-icon="mdi-time"
                    v-model="coupon.expire_in_days"
                    :label="$t('attributes.expire_in_days.label')"
                    :placeholder="$t('attributes.expire_in_days.placeholder')"
                    :hint="$t('attributes.expire_in_days.hint')"
                    :error-messages="errors.expire_in_days"
               ></v-text-field>
          </v-col>
          <v-col cols="6">
               <v-select
                    v-model="coupon.type"
                    :items="coupon_types"
                    :label="$t('attributes.type.label')"
                    return-object
                    :error-messages="errors.type"
               ></v-select>
          </v-col>
          <v-col cols="6">
               <v-select
                    v-model="coupon.valid_levels"
                    :items="valid_levels"
                    :label="$t('attributes.valid_levels.label')"
                    multiple
                    :error-messages="errors.valid_levels"
               ></v-select>
          </v-col>
          <v-col cols="6">
               <v-text-field
                    type="number"
                    :prepend-icon="(coupon.type == 'percentage') ? 'mdi-percent' : 'mdi-currency-eur'"
                    v-model="coupon.discount_amount"
                    :label="$t('attributes.discount_amount.label')"
                    :placeholder="$t('attributes.discount_amount.placeholder')"
                    :hint="$t('attributes.discount_amount.hint')"
                    :error-messages="errors.discount_amount"
               ></v-text-field>
          </v-col>
          <v-col cols="6">
               <v-text-field
                    type="number"
                    min="0"
                    max="1000"
                    v-model="coupon.points"
                    :label="$t('attributes.points.label')"
                    :placeholder="$t('attributes.points.placeholder')"
                    :hint="$t('attributes.points.hint')"
                    :error-messages="errors.points"
               ></v-text-field>
          </v-col>
          <v-col cols="12">
               <v-btn :loading="loading" block @click="createCoupon" color="primary"><v-icon>mdi-save</v-icon> SAVE</v-btn>
          </v-col>
     </v-row>
</template>

<script>
import datepicker from '@/components/Commons/FormValues/datepicker.vue';

export default {
     name: "CreateCoupon",
     components:{
          datepicker
     },
     data: () => ({
          loading: null,
          coupon:{},
          errors:{},
          message:null,
          coupon_types: ['fixed','percentage'],
          valid_levels: ['bronze','silver','gold'],
          countries: []
     }),
     mounted(){
          this.getCountries();
     },
     methods:{
          getCountries(){
               this.loading = true
               this.$store.dispatch('general/getCountries',this.reg).then((response) => {
                    this.loading = false;
                    console.log('countries',response.data)
                    this.countries = response.data;
               })
          },
          textCountryItem: item => item.country,
          changeCountry(item){
               this.coupon.country = item.country
          },
          async createCoupon(){
               this.loading = true
               console.log('CREATE COUPON',this.coupon)

               this.$store.dispatch('adminCoupons/createCoupon',this.coupon)
                    .then((response) => {
                         console.log('CREATE success!',response)
                         this.message = response.data.message
                         var coupon_uid = response.data.coupon_uid
                         this.loading = false;
                         this.$router.push({name:'admin.coupons.single',params:{'id':coupon_uid}})
                    })
                    .catch((err) => {
                         console.error('CREATE ERROR!',err)
                         this.message = err.response.data.message
                         this.errors = err.response.data.errors
                         this.loading = false;
                    }) 

          }
     }
}
</script>
